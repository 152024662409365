import en_logo from './logo.svg';
import ar_logo from './arabic-logo.svg';
import en_pdf_logo from './en-pdf-logo.png';
import ar_pdf_logo from './ar-pdf-logo.png';
import barcode from './barcode.png';
import pdf_product from './pdf_product.jpg';
import pdf_bg from './pdf_bg.png';

import player_zoom from './player_zoom.png';
import player_close from './player_close.png';
import player_minus from './player_minus.png';

import banner from './banner.png';
import second_login_banner from './second-login-bg.jpg';
import logo_2 from './landmark-logo.png';

import plus_accessories from './plus.svg';
import color from './color.svg';
import open from './open.svg';
import dimension from './dimension.svg';
import minus_accessories from './minus_accessories.svg';
import undo from './undo.svg';
import rotate_cw from './rotate_cw.svg';
import rotate_ccw from './rotate_ccw.svg';
import refresh from './refresh.svg';
import close_arrow from './close-arrow.png';
import open_arrow from './open-arrow.png';
import dummy from './dummy.jpg';

import dropdown_arrow from './dropdown_arrow.png';

const media = {
  en_logo,
  ar_logo,
  en_pdf_logo,
  ar_pdf_logo,
  barcode,
  banner,
  pdf_product,
  pdf_bg,
  second_login_banner,
  logo_2,

  undo,
  rotate_cw,
  rotate_ccw,
  refresh,
  open,
  color,
  dimension,
  plus_accessories,
  minus_accessories,

  open_arrow,
  close_arrow,
  dummy,
  player_zoom,
  player_close,
  player_minus,
  dropdown_arrow,
};
export default media;
