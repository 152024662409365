import styled from 'styled-components';

export const HidenInput = styled.input`
  position: absolute;
  left: 0;
  right: 0;
  display: inline-block;
  width: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
  margin: 0;
  padding: 0;
`;

export const Wrapper = styled.div`
  width: 48px;
  height: 48px;
  background-image: ${({ image }) => `url(${image})`};
  background-size: cover;
  border: ${({ checked }) => (!checked ? "" : "3px solid #D0A6CF")};
`;
