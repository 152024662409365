import styled from 'styled-components';
import CardGroup from '../../Components/CardGroup/CardGroup';

export const Wrapper = styled(CardGroup)``;
export const BlockTitle = styled.div`
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #212121;
  margin: 20px 0;
`;
