import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Wrapper } from './header.styles';
import i18n from '../../i18next';
import { Select } from 'antd';
import { useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import DropDown from '../DropDown/DropDown';

import {
  setCountry,
  setCurrentCollection,
  setCurrentProductCategory,
  setLanguage,
  setFromSummary,
  resetSummary,
  setModelInstanceId,
  setPlayerReady,
  setDesignId,
  resetProductConfig,
  setProductSize,
  setUndoConfigArray,
  setAccessories,
  setModelOpen,
} from '../../store/threekit';

import { Button, Form, Input, Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import media from '../../assets/index';
import { attributeIds } from '../../constants';
import { AppContext } from '../../context';
import { setConfigOnModel } from '../../utils';

export default function Header({ t }) {
  const { instance } = useMsal();
  const [isModalResumeVisible, setIsModalResumeVisible] = useState(false);
  const [modalCollection, showModalCollection] = useState(false);
  const [modalCategory, showModalCategory] = useState(false);
  const [modalCountry, showModalCountry] = useState(false);
  const { threekit: { configurator = {} } = {} } = window;
  const { reset } = configurator;
  const dispatch = useDispatch();
  const {
    collection,
    country,
    language,
    category,
    products,
    summary,
    fromSummary,
    isPlayerReady,
  } = useSelector((s) => s.threekit);
  const context = useContext(AppContext) || {};
  const { handleAddItem } = context;
  const storeCode = JSON.parse(localStorage.getItem('store-code'));

  const onResume = async ({ designId: rawDesignId }) => {
    // await reset();
    const designId = rawDesignId.trim();
    setIsModalResumeVisible(false);
    dispatch(setPlayerReady(false));
    const { items, configId, hasConfigError } = await reset({ designId });
    if (!!hasConfigError) return;

    // set summary for bed or sofas
    const summaryItems =
      items?.[0].type === 'Bed Collection'
        ? Object.values(items[0].configuration)
        : items;
    let newSummary = [];
    (summaryItems || []).forEach((product) => {
      if (!!product.type) {
        const {
          type: collection,
          productType: type,
          key: en,
          ...rest
        } = product;
        newSummary.push({ collection, type, en, ...rest });
      }
    });
    dispatch(resetSummary({ newSummary }));
    dispatch(resetProductConfig(newSummary[0]));
    dispatch(setDesignId());
    dispatch(setUndoConfigArray([configId]));
    dispatch(setPlayerReady(true));
  };

  const onResumeFailed = (errorInfo) => {
    console.log('Resume Failed:', errorInfo);
  };

  const handleLanguageChange = () => {
    const newLanguage = language === 'en' ? 'ar' : 'en';
    i18n.changeLanguage(newLanguage);
    dispatch(setLanguage(newLanguage));
  };

  const prepareBedCategory = async () => {
    const modelId = await handleAddItem({
      type: 'Bed Collection',
      key: 'Bed Model',
    });
    const instanceId = await window.threekit.api.player.getAssetInstance({
      id: modelId,
      plug: 'Null',
      property: 'asset',
    });
    dispatch(setModelInstanceId({ itemId: modelId, instanceId }));
    return instanceId;
  };

  const changeCategory = async (newCategory) => {
    dispatch(setPlayerReady(false));
    dispatch(setCurrentProductCategory(newCategory));
    let instanceId;
    if (newCategory === 'Bed') {
      instanceId = await prepareBedCategory();
    }
    const newCollection = Object.keys(products[newCategory])[0];
    return changeCollection(newCollection, newCategory, instanceId); // set first collection with new category
  };

  const onProductCategoryChange = async (value) => {
    if (!!Object.keys(summary).length) {
      showModalCategory(value);
    } else {
      changeCategory(value);
    }
  };

  const changeCollection = async (newCollection, newCategory) => {
    dispatch(setPlayerReady(false));
    dispatch(setCurrentCollection(newCollection));
    dispatch(setModelOpen(false)); // reset open model state
    // reset sofas or set bed collection attribute
    if ((!newCategory && category === 'Bed') || newCategory === 'Bed') {
      const [firstPlayerModel] = window.threekit.configurator
        .getItems()
        .values();
      const itemId = firstPlayerModel.id;

      const initialBedConfig = {
        Bedbase: { assetId: attributeIds[`Bedbase_None`] },
        Headboard: { assetId: attributeIds[`Headboard_None`] },
        Accessories: { assetId: attributeIds.Accessories_None },
      };
      await setConfigOnModel(itemId, initialBedConfig);

      dispatch(setAccessories(false)); // reset model accessories  state
      dispatch(
        setProductSize(Object.keys(products['Bed'][newCollection]['Base'])[0])
      );
    } else {
      // reset for sofa config
      dispatch(setDesignId());
      reset();
      dispatch(setPlayerReady(true));
    }
    dispatch(resetSummary());
  };
  // show modal to reset summary
  const onCollectionChange = async (value) => {
    !!Object.keys(summary).length
      ? showModalCollection(value)
      : changeCollection(value);
  };

  const onLogout = (value) => {
    if (value === 'Logout') {
      instance.logoutRedirect().catch((e) => {
        console.error(e);
      });
    }
  };

  const { Option } = Select;
  const location = useLocation();

  const history = useHistory();

  //Start Over Button Start
  const [isModalStartOver, setModalStartOver] = useState(false);
  const handleCancelStar = () => {
    setModalStartOver(false);
  };
  const startOver = async () => {
    setModalStartOver(true);
  };
  async function startOverRedirection(key) {
    if (key === true) {
      // setModalStartOver(false);
      await window.threekit.configurator.reset();
      window.threekit.editConfigId = '';
      if (typeof modalCountry === 'string') dispatch(setCountry(modalCountry));
      dispatch(setUndoConfigArray([]));
      dispatch(resetSummary());
      dispatch(setDesignId());
      dispatch(setFromSummary(1));
      if (category === 'Bed') {
        dispatch(setCurrentProductCategory('Sofa'));
        dispatch(setCurrentCollection('Eterno'));
      }
      dispatch(setPlayerReady(false));
      history.push('/configuratorArea');
    }
    setModalStartOver(false);
  }
  const modalCountryChange = (country) => {
    if (!!Object.keys(summary).length) {
      showModalCountry(country);
    } else {
      dispatch(setCountry(country));
      showModalCountry(false);
    }
  };
  const resetPlayerCountryChange = () => {
    const waitForReset = window.threekit.configurator.reset();
    if (category === 'Bed') {
      waitForReset.then(async () => {
        const instanceId = await prepareBedCategory();
        const newCollection = Object.keys(products[category])[0];
        await changeCollection(newCollection, category, instanceId);
        dispatch(setPlayerReady(true));
      });
    }
    dispatch(resetSummary());
    dispatch(setDesignId());
    dispatch(setFromSummary(1));
    dispatch(setCountry(modalCountry));
    showModalCountry(false);
  };

  //Start Over Button End
  return (
    <>
      <Wrapper className="bg-whitess" disabled={!isPlayerReady}>
        <div className="justify-content-between top-header d-flex flex-column flex-lg-row flex-md-row flex-column-fluid">
          <div className="right_menu ">
            {location.pathname !== '/itemsummary' ? (
              <>
                <div className="padding-reduce">
                  <Select
                    className="form-controls header_dropdown"
                    onChange={onProductCategoryChange}
                    // defaultValue={}
                    value={t(`header.categories.${category}`)}
                    trigger={['click']}
                  >
                    {Object.keys(products).map((category, i) => (
                      <Option key={i} value={category}>
                        {t(`header.categories.${category}`)}
                      </Option>
                    ))}
                  </Select>
                </div>

                <div className="padding-add">
                  <Select
                    className="form-controls header_dropdown"
                    onChange={onCollectionChange}
                    value={t(`common.collections.${collection}`)}
                  >
                    {Object.keys(products[category] || []).map(
                      (collection, i) => (
                        <Option key={i} value={collection}>
                          {t(`common.collections.${collection}`)}
                        </Option>
                      )
                    )}
                  </Select>
                </div>

                <div className="toggle_area">
                  {/***THIS CODE IS SHOW OLNY MOBILE MENU SIDEBAR BUT FUNCTIONALITY MUST ADDED START**/}
                  <div className="toggle-mobile-menu d-lg-none">
                    <input
                      type="checkbox"
                      className="openSidebarMenu"
                      id="openSidebarMenu"
                    />
                    <label
                      htmlFor="openSidebarMenu"
                      className="sidebarIconToggle"
                    >
                      <div className="spinners diagonal part-1"></div>
                      <div className="spinners horizontal"></div>
                      <div className="spinners diagonal part-2"></div>
                    </label>
                    <div id="sidebarMenu">
                      <ul className="menu-ul">
                        <button
                          className="custom-button resume-buttton"
                          onClick={() => setIsModalResumeVisible(true)}
                        >
                          {t('header.resumeConfiguration')}
                        </button>
                        <li className="menu-list">
                          <span className="fix-width">Location: </span>
                          <DropDown
                            country={country}
                            t={t}
                            onChange={(country) => modalCountryChange(country)}
                          />
                        </li>

                        <li className="menu-list">
                          <span className="fix-width">Language:</span>
                          <span
                            style={{ color: '#212121', fontSize: '18px' }}
                            onClick={handleLanguageChange}
                          >
                            {t('common.language')}
                          </span>
                        </li>

                        <li className="menu-list">
                          <span className="fix-width">Store</span>
                          <div className="store-inner">
                            <span style={{ color: '#212121' }}>
                              {t('header.storeId')}
                            </span>
                            <Select
                              className="form-controls storeid"
                              onChange={onLogout}
                              defaultValue={storeCode?.['Store Code'] || ''}
                            >
                              <Option value="Logout">
                                {t('header.logout')}
                              </Option>
                            </Select>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/***THIS CODE IS SHOW OLNY MOBILE MENU SIDEBAR BUT FUNCTIONALITY MUST ADDED END**/}

                  <button
                    className="custom-button resume-buttton resume-buttton-desktop"
                    onClick={() => setIsModalResumeVisible(true)}
                  >
                    {t('header.resumeConfiguration')}
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="inner-logo">
                  <img
                    src={media[`${language}_logo`]}
                    alt="Logo"
                    className="w-100"
                    onClick={() => startOver()}
                  />
                </div>

                <div className="toggle_area">
                  {/***THIS CODE IS SHOW OLNY MOBILE MENU SIDEBAR FOR ITEM SUMMERY PAGE BUT FUNCTIONALITY START***/}
                  <div className="toggle-mobile-menu d-lg-none">
                    <input
                      type="checkbox"
                      className="openSidebarMenu"
                      id="openSidebarMenu"
                    />
                    <label
                      htmlFor="openSidebarMenu"
                      className="sidebarIconToggle"
                    >
                      <div className="spinners diagonal part-1"></div>
                      <div className="spinners horizontal"></div>
                      <div className="spinners diagonal part-2"></div>
                    </label>
                    <div id="sidebarMenu">
                      <ul className="menu-ul">
                        <button
                          className="custom-button resume-buttton"
                          onClick={() => setIsModalResumeVisible(true)}
                        >
                          {t('header.resumeConfiguration')}
                        </button>
                        <li className="menu-list">
                          <span className="fix-width">Location: </span>
                          <DropDown
                            country={country}
                            t={t}
                            onChange={(country) => modalCountryChange(country)}
                          />
                        </li>

                        <li className="menu-list">
                          <span className="fix-width">Language:</span>
                          <span
                            style={{ color: '#212121', fontSize: '18px' }}
                            onClick={handleLanguageChange}
                          >
                            {t('common.language')}
                          </span>
                        </li>

                        <li className="menu-list">
                          <span className="fix-width">Store</span>
                          <div className="store-inner">
                            <span style={{ color: '#212121' }}>
                              {t('header.storeId')}
                            </span>
                            <Select
                              className="form-controls storeid"
                              onChange={onLogout}
                              defaultValue={storeCode?.['Store Code'] || ''}
                            >
                              <Option value="Logout">
                                {t('header.logout')}
                              </Option>
                            </Select>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/***THIS CODE IS SHOW OLNY MOBILE MENU SIDEBAR FOR ITEM SUMMERY PAGE BUT FUNCTIONALITY MUST ADDED***/}
                </div>
              </>
            )}
          </div>

          {/***THIS CODE IS SHOW DEAKTOP FOR ITEM SUMMERY PAGE BUT FUNCTIONALITY MUST ADDED***/}
          <div className="left-desktop-menu align-items-center justify-content-lg-end d-flex justify-content-md-end justify-content-center flex-lg-nowrap flex-wrap w-100">
            <div className="left-_side_menu">
              <div className="combine">
                <DropDown
                  country={country}
                  t={t}
                  onChange={(country) => modalCountryChange(country)}
                />

                <div className="language">
                  <span onClick={handleLanguageChange}>
                    {t('common.language')}
                  </span>
                </div>
              </div>
            </div>

            <div className="left-_side_menu">
              <div className="align-items-center d-flex justify-content-end storeid-outer">
                <span>{t('header.storeId')}</span>
                <span>
                  <Select
                    className="form-controls storeid"
                    onChange={onLogout}
                    defaultValue={storeCode?.['Store Code'] || ''}
                  >
                    <Option value="Logout">{t('header.logout')}</Option>
                  </Select>
                </span>
              </div>
            </div>
          </div>
          {/***THIS CODE IS SHOW DEAKTOP FOR ITEM SUMMERY PAGE BUT FUNCTIONALITY MUST ADDED***/}
        </div>
      </Wrapper>
      <Modal
        className="resume-modal"
        //title={t('header.resumeConfiguration')}
        visible={isModalResumeVisible}
        centered
        onOk={onResume}
        onCancel={() => setIsModalResumeVisible(false)}
        footer={null}
      >
        {/*Resume Configuration POP-UP*/}
        <div className="resum-modal">
          <label>{t('header.resumeConfiguration')}</label>
          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onResume}
            onFinishFailed={onResumeFailed}
            autoComplete="off"
          >
            <Form.Item
              label={t('modal.design_id')}
              name="designId"
              rules={[
                {
                  required: true,
                  message: t('modal.pleaseInputDesignId'),
                },
                () => ({
                  async validator(_, value) {
                    const designId = value?.trim();
                    if (!value) return Promise.reject();
                    else if (isNaN(designId))
                      return Promise.reject(t('modal.onlyNumbersRequired'));
                    else if (designId.length < 8)
                      return Promise.reject(t('modal.min8DigitsRequired'));
                    else if (designId.length > 8)
                      return Promise.reject(t('modal.max8DigitsRequired'));

                    const { hasConfigError } = await reset({
                      designId,
                      isInputValidator: true,
                    });
                    if (!!hasConfigError)
                      return Promise.reject(t('modal.hasConfigError'));

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {t('modal.resume')}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>

      {/*Start Over ON Logo Click Modal Start*/}
      <Modal
        visible={isModalStartOver}
        onCancel={handleCancelStar}
        centered
        footer={null}
      >
        <div className="save-later-modal">
          <h3>{t('modal.unsavedConfig')}</h3>
          <p className="title">{t('modal.wantReset')}</p>
          <Button onClick={() => startOverRedirection(false)}>
            {t('modal.no')}
          </Button>
          <Button onClick={() => startOverRedirection(true)}>
            {t('modal.yes')}
          </Button>
        </div>
      </Modal>

      {/*Start Over ON Logo Click Modal End*/}

      {/* Reset on category */}
      <Modal
        visible={!!modalCategory}
        onCancel={() => {
          showModalCategory(false);
        }}
        centered
        footer={null}
      >
        <div className="save-later-modal">
          <h3>{t('modal.youHaveModels')}</h3>
          <p className="title">{t('modal.wantReset')}</p>
          <Button
            onClick={() => {
              showModalCategory(false);
            }}
          >
            {t('modal.no')}
          </Button>
          <Button
            onClick={() => {
              reset();
              dispatch(setDesignId());
              changeCategory(modalCategory);
              showModalCategory(false);
            }}
          >
            {t('modal.yes')}
          </Button>
        </div>
      </Modal>

      {/* Reset on collection */}
      <Modal
        visible={!!modalCollection}
        onCancel={() => {
          showModalCollection(false);
        }}
        centered
        footer={null}
      >
        <div className="save-later-modal">
          <h3>{t('modal.youHaveModels')}</h3>
          <p className="title">{t('modal.wantReset')}</p>
          <Button
            onClick={() => {
              showModalCollection(false);
            }}
          >
            {t('modal.no')}
          </Button>
          <Button
            onClick={() => {
              changeCollection(modalCollection);
              showModalCollection(false);
              dispatch(setPlayerReady(true));
            }}
          >
            {t('modal.yes')}
          </Button>
        </div>
      </Modal>

      {/* Reset on Country Changes Start */}
      <Modal
        visible={!!modalCountry}
        onCancel={() => {
          showModalCountry(false);
        }}
        centered
        footer={null}
      >
        <div className="save-later-modal">
          <h3>{t('modal.youHaveModels')}</h3>

          <p className="title">{t('modal.wantReset')}</p>

          <Button
            onClick={() => {
              showModalCountry(false);
            }}
          >
            {t('modal.no')}
          </Button>

          <Button
            onClick={() => {
              if (fromSummary > 1) {
                startOverRedirection(true);
              } else {
                resetPlayerCountryChange();
              }
            }}
          >
            {t('modal.yes')}
          </Button>
        </div>
      </Modal>
      {/* Reset on Country Changes End */}
    </>
  );
}
