import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import axios from 'axios';
import { customAlphabet } from 'nanoid';
import {
  defaultCountry,
  defaultProductCategory,
  defaultType,
  countryCode,
  PRODUCT_COLOR_IDS,
  kingBedSizeName,
  CONFIG_LIST_API,
} from '../constants';

/*****************************************************
 * State
 ****************************************************/

const nanoid = customAlphabet('1234567890', 8);

const getUniqueDesignId = async (designId = nanoid()) => {
  const metadata = JSON.stringify({ designId }); // find configId with metadata
  const configListUrl = `${CONFIG_LIST_API}&metadata=${metadata}`;
  const configList = (await axios(configListUrl))?.data?.configurations || []; // get all config list
  if (configList.length)
    setTimeout(() => {
      getUniqueDesignId();
    }, 1000);
  else return designId;
};
export const setDesignId = createAsyncThunk(
  'threekit/setDesignId',
  async (id, thunkAPI) => {
    const designId = await getUniqueDesignId();
    return designId;
  }
);

const initialState = {
  addons: [],
  addOnsProduct: '',
  addOnsSummary: {},
  category: defaultProductCategory,
  collection: 'Eterno',
  color: '',
  colorConfig: {
    items: [],
    value: 0,
    models: {},
  },
  country: defaultCountry,
  countryCode: '',
  currentConfigId: '',
  customerInfo: {},
  designId: '',
  fromSummary: 1,
  hasAccessories: false,
  isModelOpen: false,
  isPlayerReady: false,
  language: 'en',
  modelInstanceIds: {},
  models: [],
  product: '',
  products: {},
  sessionDesignId: '',
  showDimensions: false,
  size: defaultProductCategory,
  storeCodes: [],
  summary: [],
  type: defaultProductCategory,
  undoConfigArray: [],
  undoUIMap: {},
  playerSnapshot: '',
  customerDetailsButtonsDisabled: false,
};
initialState.countryCode = countryCode[initialState.country];

const { actions, reducer } = createSlice({
  name: 'threekit',
  initialState,
  reducers: {
    setCustomerDetailsButtonsDisabled(state, action){
      state.customerDetailsButtonsDisabled = !!action.payload;
    },
    setShowDimensions: (state, action) => {
      state.showDimensions = action.payload;
    },
    setPlayerReady: (state, action) => {
      state.isPlayerReady = action.payload;
    },
    //  Loading Trackers
    saveCurrentConfigId: (state, action) => {
      state.currentConfigId = action.payload;
    },
    setUndoConfigArray: (state, action) => {
      state.undoConfigArray = action.payload;

    },
    setModels: (state, action) => {
      state.models = action.payload;
    },

    setProducts: (state, { payload }) => {
      state.products = payload;
      // assign keys: category => collection => type => size => shortName
      const collections = payload[defaultProductCategory];
      const initialCollection = Object.keys(collections)[0];

      const types = collections[initialCollection];
      const initialType = Object.keys(types)[0];

      const sizes = types[initialType];
      const sizeKeys = Object.keys(sizes);
      const kingSizeFindIndex = sizeKeys.findIndex(
        (i) => i === kingBedSizeName
      );
      const kingSizeIndex = kingSizeFindIndex > -1 ? kingSizeFindIndex : 0;
      const initialSize = sizeKeys[kingSizeIndex]; // set initial bed size as 'King' or [0]

      const products = sizes[initialSize];
      const initialProduct = Object.keys(products)[0];

      state.collection = initialCollection;
      state.type = initialType;
      state.size = initialSize;
      state.product = initialProduct;
    },

    setAddons: (state, { payload }) => {
      state.addons = payload;
    },

    setCurrentProductCategory: (state, { payload }) => {
      state.type =
        state.category === defaultProductCategory
          ? defaultType
          : defaultProductCategory;
      state.category = payload;
    },
    setCurrentCollection: (state, { payload }) => {
      state.collection = payload;

      const types = state.products[state.category][payload];
      const initialType =
        state.category === defaultProductCategory
          ? defaultProductCategory
          : defaultType;

      const sizes = types[initialType];
      const sizeKeys = Object.keys(sizes);

      const kingSizeFindIndex = sizeKeys.findIndex(
        (i) => i === kingBedSizeName
      );
      const kingSizeIndex = kingSizeFindIndex > -1 ? kingSizeFindIndex : 0;
      const initialSize = sizeKeys[kingSizeIndex || 0]; // set initial size as 'King' or [0]

      const products = sizes[initialSize];
      const initialProduct = Object.keys(products)[0];

      state.type = initialType;
      state.size = initialSize;
      state.product = initialProduct;

      state.summary = [];
    },
    setProductType: (state, { payload }) => {
      state.type = payload;

      const sizes = state.products[state.category][state.collection][payload];

      const products = sizes[state.size] || {};
      const initialProduct = Object.keys(products)?.[0];

      state.product = initialProduct;
    },
    setProductSize: (state, { payload }) => {
      state.size = payload;
    },

    setProduct: (state, { payload }) => {
      const { short } = payload;
      state.product = short;
      const newSummaryItem = {
        ...payload,
        collection: state.collection,
        size: state.size,
        color: state.color,
        qty: 1,
      };
      state.summary = [...state.summary, newSummaryItem];
    },

    removeProduct: (state, { payload }) => {
      let { summary } = state;

      const newSummary = summary.filter(
        (product) => product.modelId !== payload
      );
      state.summary = newSummary;
    },

    removeProductByType: (state, { payload }) => {
      const newSummary = state.summary.filter((i) => i.type !== payload);
      // state.color = '';
      // if (!newSummary.length) state.color = null; // reset colors
      state.summary = newSummary;
    },

    resetSummary: (
      state,
      { payload: { newSummary = [], newAddOnsSummary = {} } = {} }
    ) => {
      state.summary = newSummary;
      state.addOnsSummary = newAddOnsSummary;
    },

    setFromSummary: (state, action) => {
      state.fromSummary = action.payload;
    },

    setAddonsProduct: (state, { payload }) => {
      const { short, setQauntity } = payload;
      let editAddOnsSummary = state.addOnsSummary;
      state.addOnsProduct = short;
      let newAddonSummaryItem = {
        ...payload,
        collection: state.collection,
        size: state.size,
        qty: !!state.addOnsSummary[payload.sku]
          ? setQauntity === 'substraction'
            ? state.addOnsSummary[payload.sku].qty - 1
            : state.addOnsSummary[payload.sku].qty + 1
          : 1,
      };
      if (newAddonSummaryItem.qty === 0) {
        delete editAddOnsSummary[payload.sku];
      } else {
        delete newAddonSummaryItem['setQauntity'];
      }
      state.addOnsSummary =
        newAddonSummaryItem.qty === 0
          ? editAddOnsSummary
          : { ...state.addOnsSummary, [payload.sku]: newAddonSummaryItem };
    },

    setProductColor: (state, { payload }) => {
      state.color = payload;
    },

    setLanguage: (state, { payload }) => {
      state.language = payload;
    },

    setCountry: (state, { payload }) => {
      state.country = payload;
      for (var key in countryCode) {
        var value = countryCode[key];
        if (key === state.country) state.countryCode = value;
      }
    },

    setAccessories: (state, { payload }) => {
      state.hasAccessories = payload;
    },
    setModelOpen: (state, { payload }) => {
      state.isModelOpen = payload;
    },
    setStoreCodes: (state, action) => {
      state.storeCodes = action.payload;
    },
    setProceedState: (state, action) => {
      state.fromSummary = state.fromSummary + action.payload;
    },
    setCustomerInfo: (state, action) => {
      state.customerInfo = action.payload;
    },
    setPlayerSnapshot: (state, action) => {
      state.playerSnapshot = action.payload;
    },

    setColorItems: (state, action) => {
      state.colorConfig = {
        ...state.colorConfig,
        items: action.payload,
      };
    },
    setColorValue: (state, action) => {
      state.colorConfig = {
        ...state.colorConfig,
        value: action.payload,
      };
    },
    setModelColorValue: (state, { payload: { selected, newColor } }) => {
      // transform model ids (one or all) to array

      const modelIds =
        !!selected && state.category !== 'Bed'
          ? [selected]
          : state.summary.map((i) => i.modelId);

      // update summary product
      modelIds.forEach((modelId) => {
        const productIndex = state.summary.findIndex(
          (item) => item.modelId === modelId
        );
        if (productIndex > -1) {
          const { category, collection, type, size, short } =
            state.summary[productIndex];

          const productColorObj = current(
            state.products[category][collection][type][size]?.[short]?.[
              newColor
            ]
          );

          const configuration = {
            Color: {
              assetId: PRODUCT_COLOR_IDS[collection][newColor],
            },
          };
          const newSummary = {
            ...state.summary[productIndex],
            ...productColorObj,
            color: newColor,
            configuration,
          };

          state.summary[productIndex] = newSummary;

          window.threekit.configurator.configureItems(modelId, {
            ...newSummary,
            type: newSummary.collection,
            key: newSummary.en,
            productType: newSummary.type,
            qty: 1,
            color: newColor,
          });
        }
      });
    },
    setAllModelsColorValue: (state, action) => {
      const newModelsValues = {};
      Object.keys(state.colorConfig.models).forEach((modelId) => {
        newModelsValues[modelId] = action.payload;
      });

      state.colorConfig = {
        ...state.colorConfig,
        models: newModelsValues,
      };
    },

    // save instance to optimize scene calls
    setModelInstanceId: (state, { payload }) => {
      state.modelInstanceIds = {
        ...state.modelInstanceIds,
        [payload.itemId]: payload.instanceId,
      };
    },

    setSessionDesignId: (state, { payload }) => {
      state.sessionDesignId = payload;
    },

    resetProductConfig: (
      state,
      { payload: { collection, category, type, size, color } }
    ) => {
      state.category = category;
      state.collection = collection;
      state.type = type;
      state.size = size;
      state.color = color;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(setDesignId.fulfilled, (state, action) => {
      state.designId = action.payload;
    });
  },
});

/*****************************************************
 * Actions
 ****************************************************/

//  Actions to be used only internally
// const { } = actions;

//  Actions to be used only internally and externally
export const {
  setPlayerReady,
  saveCurrentConfigId,
  setUndoConfigArray,
  setModels,
  setProducts,
  setAddons,
  setLanguage,
  setCurrentCollection,
  setCurrentProductCategory,
  setProductType,
  setProductSize,
  setStoreCodes,
  setCountry,
  setAccessories,
  setModelOpen,
  setProductColor,
  setProduct,
  setAddonsProduct,
  setProceedState,
  setCustomerInfo,
  setPlayerSnapshot,
  removeProduct,
  removeProductByType,
  resetSummary,
  setFromSummary,
  setColorItems,
  setColorValue,
  setModelColorValue,
  setAllModelsColorValue,
  setModelInstanceId,
  // setDesignId,
  setSessionDesignId,
  resetProductConfig,
  setShowDimensions,
  setCustomerDetailsButtonsDisabled,
} = actions;

export default reducer;
