import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPlayerReady } from '../../store/threekit';
import { setConfigOnModel } from '../../utils';
import { HidenInput, Wrapper } from './ColorCard.styles';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';

const ColorCard = ({
  id,
  value,
  name,
  checked,
  onChange,
  disabled,
  image,
  assetId,
  colorName,
  selectedModel,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { category } = useSelector((s) => s.threekit);
  const onChangeRadio = async (event) => {
    const { checked } = event.target;

    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();

    onChange(event, checked, value);

    if (category === 'Sofa') {
      try {
        dispatch(setPlayerReady(false));
        const colorConfig = {
          Color: {
            assetId: assetId,
          },
        };

        if (selectedModel) {
          // Specific model selected, therefore apply color only to the selected Model
          await setConfigOnModel(selectedModel.id, colorConfig);
        } else {
          // No Models selected, therefore apply color to all models
          const allModels = window.threekit?.api?.scene?.getAll({
            type: 'Model',
            evalNode: true,
          });
          for (const modelId of Object.keys(allModels)) {
            await setConfigOnModel(modelId, colorConfig);
          }
        }
      } catch (err) {
        console.log(`Err: can't change color`, err);
        dispatch(setPlayerReady(true));
      }
      dispatch(setPlayerReady(true));
    }
  };

  return (
    <>
      <Tooltip placement="top" title={t(`common.colors.${colorName}`)}>
        <label className={`color-label selector_${id}`}>
          <Wrapper {...{ checked, image }} />
          <HidenInput
            type="radio"
            value={value}
            name={name}
            checked={checked}
            onChange={onChangeRadio}
          />
        </label>
      </Tooltip>
    </>
  );
};

export default ColorCard;
