import React from 'react';

const ArrowDown = (props) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 386.257 386.257"
      xmlSpace="preserve"
      {...props}
    >
      <polygon points="0,96.879 193.129,289.379 386.257,96.879 " />
    </svg>
  );
};

export default ArrowDown;
