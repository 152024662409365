import React, { useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PRODUCT_COLOR_IMAGES, PRODUCT_COLOR_IDS } from '../../constants';
import { AppContext } from '../../context';

import ColorCard from '../../Components/ColorCard/ColorCard';
import CardGroup from '../../Components/CardGroup/CardGroup';
import { ColorTitle } from './ColorBlock.styles';
import {
  setUndoConfigArray,
  setModelColorValue,
  setProductColor,
} from '../../store/threekit';
import { setBedAttributes } from '../../utils/attributes';

export const getColorImage = (collection, color) => {
  return PRODUCT_COLOR_IMAGES[collection]
    ? PRODUCT_COLOR_IMAGES[collection][color]
    : '';
};

export const getColorAssetId = (collection, color) => {
  return PRODUCT_COLOR_IDS[collection]
    ? PRODUCT_COLOR_IDS[collection][color]
    : '';
};

const ColorBlock = ({ t, colorList }) => {
  const dispatch = useDispatch();

  const [inputValue, setInputValue] = useState(null);

  const { selected, handleGetItem } = useContext(AppContext);
  const selectedModel = handleGetItem(selected);

  const { category, collection, type, color, undoConfigArray, designId } =
    useSelector((s) => s.threekit);

  useEffect(() => {
    setInputValue(colorList.indexOf(color)); // set color index on external config change (edit, resume)
  }, [color, colorList]);

  const handleChange = async (e) => {
    const colorIndex = e.target.value;

    const newColor = colorList[colorIndex];

    dispatch(setProductColor(newColor));
    setInputValue(colorIndex);

    if (category === 'Sofa') {
      dispatch(setModelColorValue({ selected, newColor }));

      // TODO: refactor undo to a single function
      const configId = await window.threekit.configurator.saveConfiguration({
        designId,
        collection,
        type,
        color: newColor,
      });
      const newPlayerConfigs = [...undoConfigArray, configId];
      dispatch(setUndoConfigArray(newPlayerConfigs));
    } else {
      setBedAttributes({ newColor });
    }
  };
  const selectedColor = color || colorList[0];

  return (
    <div>
      <ColorTitle>{t(`common.colour`)}</ColorTitle>
      <CardGroup value={inputValue} onChange={handleChange} name="colors">
        {colorList.sort().map((color, index) => (
          <ColorCard
            id={index}
            key={index}
            value={index}
            colorName={color}
            className="color-label"
            image={getColorImage(collection, color)}
            assetId={getColorAssetId(collection, color)}
            selectedModel={selectedModel}
          />
        ))}
      </CardGroup>
      <div className="color-select">
        {`${t('sidebar.selectedColor')}${t(`common.colors.${selectedColor}`)}`}
      </div>
    </div>
  );
};

export default ColorBlock;
