import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  width: fit-content;
  padding: 8px 8px 20px;
  border: ${({ checked }) =>
    checked ? '2px solid #7665A0' : '1px solid #00000020'};
  min-height: 253px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 2px;
  box-sizing: border-box;
  background-color: ${(props) => (props.checked ? '' : '#fff')};
  /* cursor: pointer; */
  cursor: ${({ disabled }) => (disabled ? 'wait' : 'grab')};
  position: relative;

  [disabled] {
    pointer-events: none;
    opacity: 0.7;
  }
`;
export const CardImage = styled.img`
  width: 184px;
`;

export const ProductTitle = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
`;
export const ProductPrice = styled.div`
  color: #212121;
  font-size: 14px;
  line-height: 20px;
  margin-top: 7px;
`;
export const HiddenInput = styled.input`
  position: absolute;
  left: 0;
  right: 0;
  display: inline-block;
  width: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
  margin: 0;
  padding: 0;
`;
export const ImageWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;
