import styled from 'styled-components';

export const Wrapper = styled.div`
  .mr-4 {
    margin-right: 1.5rem !important;
  }

  .hdkHde.item_price {
    font-size: 15px;
  }
  .combine_price {
    display: inline-block;
  }
  .pl-0 {
    padding-left: 0 !important;
  }
  .symbol.symbol-90 .symbol-label {
    width: 80px;
    height: 80px;
  }
  .symbol .symbol-label {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-color: #f3f6f9;
  }

  .product-style-comeon {
    font-weight: 700;
    font-size: 15px;
    color: #000 !important;
    text-decoration: none !important;
    letter-spacing: 0.3px;
  }

  .product-name-details span {
    font-size: 14px;
    color: #747475;
    font-weight: 400;
    margin: 4px 0 0 0;
  }
  span.all_price_bold {
    color: #212121;
  }
  span.main-price {
    color: #212121;
    margin: 0 3px;
    text-decoration: line-through;
    font-weight: 900;
  }
  span.main-price.false {
    color: #747475;
  }
  span.sale-price {
    color: #d21818;
    font-weight: 900;
  }

  .product-addon {
    padding: 14px 14px 14px;
    border: 1px solid #00000020;
    height: 100%;
    width: 100%;
  }

  .title-addon-product {
    font-size: 0.8em;
    color: #212121;
    font-weight: 400;
    float: left;
    width: 50%;
  }
  .addon_price {
    margin: 10px 0 0 0;
    clear: both;
    word-break: break-all;
  }
  .count-number button {
    border: none;
    background-color: transparent;
    padding: 0px 10px;
    font-weight: 700;
  }
  .count-number {
    border: 1px solid rgba(0, 0, 0, 0.125);
    box-sizing: border-box;
    border-radius: 2px;
    width: 30%;
    color: #212121;
  }
  .outer-table {
    background-color: #fdfdfd;
    padding: 15px;
    border: 1px solid #c8c9c6;
    height: 100%;
  }
  .main-price.no-discounted-price {
    text-decoration: none !important;
  }

  .outer-table .autoscroll {
    display: block;
    overflow: auto;
  }

  /*****************NEW TABLE******************/
  .mobile-full-product-area {
    display: none;
  }
  .product-image {
    float: left;
    width: 45%;
  }

  .product-details {
    float: left;
    width: 37%;
  }

  .product-price {
    float: left;
    width: 26%;
    padding: 0 1rem 0 0;
    word-break: break-all;
  }

  .product-quantity {
    float: left;
    width: 10%;
  }

  .product-line-price {
    float: left;
    width: 12%;
    text-align: right;
  }

  .inner-label {
    color: rgba(0, 0, 0, 0.3);
    font-family: 'Frutiger LT Std';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
  }

  /* Column headers */

  .column-labels {
    border-bottom: 1px solid #c8c9c6;
    margin-bottom: 15px;
    clear: both;
    overflow: hidden;
  }

  .column-labels label {
    padding-bottom: 15px;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #000000;
  }

  /* Product entries */
  .product {
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
    overflow: hidden;
    clear: both;
  }
  .product .product-image img {
    width: 100px;
  }
  .modular_item_name {
    padding-bottom: 15px;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #000000;
    display: none;
  }

  /* Make adjustments for tablet */
  @media screen and (max-width: 991px) {
    .title-addon-product {
      float: left;
      width: 100%;
    }
    .count-number button {
      padding: 3px 10px;
    }
    .product {
      padding: 0 15px 10px;
    }
    .column-labels label {
      padding: 0 15px 15px;
    }
    .modular_item_name {
      display: block;
      border-bottom: 1px solid #eee;
      margin-bottom: 20px;
      padding: 0 15px 15px;
    }
    .product-style-comeon {
      font-size: 16px;
    }
    .column-labels,
    .mobile-hiddden {
      display: none;
    }
    .column-labels.addon-label {
      display: block;
    }
    .mobile-full-product-area {
      display: block;
    }

    .product-image {
      float: right;
      width: 100%;
    }
    .mobile-full-product-area .footer-price {
      float: left;
      width: 50%;
    }

    .product-details {
      float: none;
      margin-bottom: 10px;
      width: auto;
    }

    .product-price {
      clear: both;
      width: 70px;
    }

    .product-quantity {
      width: 50%;
      text-align: right;
    }

    .product-line-price {
      width: 100%;
      text-align: left;
      margin-top: 10px;
    }
  }
  /* Make more adjustments for phone */
  @media screen and (max-width: 350px) {
    .product-line-price {
      float: right;
      clear: left;
      width: auto;
    }
  }

  /*****************NEW TABLE******************/
`;
