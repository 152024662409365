export const REACT_APP_THREEKIT_HOST = process.env.REACT_APP_THREEKIT_HOST;
export const REACT_APP_THREEKIT_ORG = process.env.REACT_APP_THREEKIT_ORG;
export const REACT_APP_THREEKIT_AUTH_TOKEN =
  process.env.REACT_APP_THREEKIT_AUTH_TOKEN;

// threekit id
export const authToken = REACT_APP_THREEKIT_AUTH_TOKEN;
const orgId = REACT_APP_THREEKIT_ORG;
const storeCodeDataTable = 'dc63ce8c-1e43-4b6d-ad7d-3d083436b0b1'; // 'b1333b63-425e-4c34-b53b-afd25092651b';
const productDataTable = 'f8cf8200-1649-45bc-bd86-6cff43cb95a5'; // '66c2d0b3-1780-48fd-bab4-f47b299bcdbd';
const addonDataTable = '587dc6af-54f6-42b6-80be-66cc01a87a4a'; //b13cd0e6-7376-4031-be82-7fd1ee29b5dd
// API f053c6bd-24e5-43eb-a71b-30c498948f6c b63e4fd5-e1de-42e2-a2e2-c76f360e7f08
export const rowValueApi = `${REACT_APP_THREEKIT_HOST}/api/datatables/${storeCodeDataTable}/row?orgId=${orgId}&bearer_token=${authToken}`;
export const productApi = `${REACT_APP_THREEKIT_HOST}/api/datatables/${productDataTable}/rows?&orgId=${orgId}&bearer_token=${authToken}&all=true`;
export const addonApi = `${REACT_APP_THREEKIT_HOST}/api/datatables/${addonDataTable}/rows?&orgId=${orgId}&bearer_token=${authToken}&all=true`;
export const orderApi = `${REACT_APP_THREEKIT_HOST}/api/orders?bearer_token=${REACT_APP_THREEKIT_AUTH_TOKEN}`;
//export const landMarkAPI = `https://apidev.landmarkgroup.com/landmarkgroup/retail-ft/v2/concepts/4/territories/${storeCode['Territory Code']}/locations/${storeCode['Store Code']}/modular-designs/${store_Designer_Id}`;
export const emailPostMarkApi = 'https://landmark-email.3kit.com/'; // `https://demo.superdna3dlab.com/land-mark/`; //https://landmark-email.3kit.com/

// Product
export const productColors = {
  // sofa
  Caramel: '#C68E17',
  Cloud: '#C2BFBA',
  Green: '#008000',
  Ocean: '#2B65EC',
  Stone: '#38464B',
  Sand: '#C2B280',
  // bed
  'Blush Pink': '#E8C7C8',
  'Dark Blue': '#00008B',
  'Dark Brown': '#654321',
  'Burnt Orange': '#BF5700',
  'Light Beige': '#EAD2A8',
  'Dark Grey': '#808080',
  Grey: '#A9A9A9',
  Black: '#000000',
  Teal: '#008080',
  Beige: '#F5F5DC',
  White: '#FFFFFF',
  Charcoal: '#36454F',
  Cream: '#FFFDD0',
  defaultColor: 'red',
};

export const PRODUCT_COLOR_IMAGES = {
  Eterno: {
    Caramel: '/colors/eterno/caramel.png',
    Cloud: '/colors/eterno/cloud.png',
    Green: '/colors/eterno/green.png',
    Ocean: '/colors/eterno/ocean.png',
    Stone: '/colors/eterno/stone.png',
    Sand: '/colors/eterno/sand.png',
  },

  Levin: {
    Grey: '/colors/levin/grey.png',
    'Dark Grey': '/colors/levin/dark_grey.png',
  },

  Colette: {
    Black: '/colors/colette/black.png',
    'Blush Pink': '/colors/colette/blush_pink.png',
    'Dark Blue': '/colors/colette/dark_blue.png',
    'Dark Brown': '/colors/colette/dark_brown.png',
    Teal: '/colors/colette/teal.png',
    Beige: '/colors/colette/beige.png',
    'Burnt Orange': '/colors/colette/burnt_orange.png',
    White: '/colors/colette/white.png',
    Grey: '/colors/colette/grey.png',
  },

  Estate: {
    White: '/colors/estate/white.png',
  },

  Stellar: {
    Charcoal: '/colors/stellar/charcoal.png',
    Grey: '/colors/stellar/grey.png',
    'Light Beige': '/colors/stellar/beige.png',
    Cream: '/colors/stellar/cream.png',
  },
};

export const PRODUCT_COLOR_IDS = {
  Eterno: {
    Caramel: 'cd150740-29ea-4fbd-a6d9-483f92af45e9',
    Cloud: 'e37b1885-82ae-4fe7-9ec7-421d8cf4a108',
    Green: 'a3a9b71a-b01a-4f88-890a-18b0d4124939',
    Ocean: '1b96ee54-d895-4672-9ea5-0d799ba1f378',
    Stone: '5586fdb8-6a1c-44b2-8555-c0fe646e25e2',
    Sand: 'ebd3b7f0-4536-4113-9c44-1e6675eaf36e',
  },

  Levin: {
    Grey: '6a0ae40c-fa22-436d-ba8e-75135063551f',
    'Dark Grey': '159efdf5-6743-4ade-9caa-065bf27b744f',
  },

  Colette: {
    Black: 'e026c09d-50cb-4c68-848d-cc4ca421fb7c',
    'Blush Pink': '8d6ff078-9a33-4b41-b839-7d1651622520',
    'Dark Blue': 'f8348d15-31aa-49a6-87f2-01908e838cf7',
    'Dark Brown': '86406360-34d5-40db-a6ae-c7aed72b9d40',
    Teal: 'cf3bfd07-722b-40ee-8ba5-ecf2fda89d45',
    Beige: '41d55c24-0182-44ec-82aa-b0db32b5365e',
    'Burnt Orange': 'ce81ebbe-1a11-429c-aaa1-793c87af6dd5', // 'db8c6670-c695-4cdf-8127-9a82204b878d',
    White: 'faa5b333-60c5-4ddc-91e5-3286a124056b',
    Grey: '87bef871-4f4a-40f2-8014-af78f1c7606f',
  },

  Estate: {
    White: '632a71f1-e61b-4c83-a841-0f334dc95ec5',
  },

  Stellar: {
    Charcoal: 'e191b590-36c1-4b78-b382-e56f7db2c441',
    Grey: 'a32eba1d-7220-426f-9419-0598b94cb9c5',
    'Light Beige': '33b5af89-7a9b-46b0-989a-0632af449082',
    Cream: '296022c1-ea5d-475b-8fcc-075e21fe69d4',
  },
};

export const defaultProductCategory = 'Sofa';
export const defaultType = 'Base';
export const storeCodeColumn = 'Store Code';
export const currencyList = ['AED', 'KWD', 'BD', 'OMR', 'SAR', 'EGP', 'QAR'];
export const countryList = [
  'AE',
  'KW',
  'BH',
  'OM',
  'EG',
  'QA',
  'DM',
  'JE',
  'RY',
];
// const currencyArrayList = {
//   AED: 'AE',
//   KWD: 'KW',
//   BD: 'BH',
//   OMR: 'OM',
//   SAR: 'SA',
//   EGP: 'EG',
//   QAR: 'QA',
// };

export const countryCode = {
  AE: '971',
  KW: '965',
  BH: '973',
  OM: '968',
  DM: '966',
  JE: '966',
  RY: '966',
  EG: '20',
  QA: '974',
};

export const currencyCoutryList = {
  AE: 'AED',
  KW: 'KWD',
  BH: 'BHD',
  OM: 'OMR',
  JE: 'SAR',
  RY: 'SAR',
  DM: 'SAR',
  EG: 'EGP',
  QA: 'QAR',
};

const storeCode = JSON.parse(localStorage.getItem('store-code'));
export const defaultCountry = storeCode?.['Territory'] || countryList[0];
// currencyArrayList?.[storeCode?.['Currency Code']] || countryList[0];

export const SECTIONS = {
  productBlock: 'productBlock',
  addItem: 'addItem',
  configureItem: 'configureItem',
  floorConfigrator: 'floorConfigurator',
};

export const imageFallback = `../../assets/Homecentre_Watermark_Threekit.png`;

export const playerSvgIcon = {
  Rotate: 'Undo',
  Refresh: 'Start Over',
  Open: 'Open',
  plus_accessories: 'Add Accessories',
  minus_accessories: 'Remove Accessories',
  Dimension: 'Dimension',
  Player_zoom: 'Zoom',
  Player_minus: 'Zoom-Out',
  Player_close: 'Close',
};

export const openModels = [
  'Eterno Fabric Storage Ottoman',
  'Eterno Modular Sofa Console',
];

export const modelAttributes = {
  color: 'Color',
  collection: 'Ranges',
  size: 'Size',
};

// static ids are here to improve performance
export const attributeIds = {
  // SOFA

  // Open / Closed
  'Eterno Modular Sofa Console': '48f6ad65-d98f-4cad-8caf-4ca359ba78ee',
  'Eterno Modular Sofa Console_Open': 'd929a4f2-4897-4c22-ba9f-13589f4952a9',
  'Eterno Fabric Storage Ottoman': 'b6450d8b-051f-4776-bf6c-15d3a3cd155d',
  'Eterno Fabric Storage Ottoman_Open': 'dbfdd676-74f1-40e2-a9f3-94dd863152a0',

  Base: 'Bedbase',
  Headboard: 'Headboard',
  // Size
  Sofa: 'King', // default size on category change
  'King - 180x210 cm': 'King',
  'King - 180x200 cm': 'King',
  'Queen - 155x205 cm': 'Queen',
  'Single - 120x200 cm': 'Single ( 120 cm )',
  'Single - 90x200 cm': 'Single ( 90 cm )',
  'Super King - 200x210 cm': 'Super King',

  // Accessories

  // Bedbase
  Bedbase_None: '39d3a46a-e22d-4d07-aed2-10f17fa9fce3',
  'Colette Queen 2-Drawer Bed Base 155x205_Open':
    'af5f2c18-78ad-464a-8e30-1e4203a4edd3',
  'Colette Single 2-Drawer Bed Base 120x200_Open':
    '140db4a9-0ae2-42a6-8843-b03acd2656ab',
  'Colette Single 2-Drawer Bed Base 90x200_Open':
    '263f3130-3d66-4a2d-ab12-ebf94323f1fb',
  'Colette Super King 4-Drawer Bed Base 200x210 cm_Open':
    '54b9d27d-b4c1-42c7-85cd-1423b7b19dd0',
  'Colette King 4-Drawer Bed Base - 180x210 cm_Open':
    '1a24b374-b240-4df1-9ff3-8d8beb164870',

  'Colette Queen 2-Drawer Bed Base 155x205':
    '14c7cc79-84ea-49c6-a311-46bab98c5208',
  'Colette Single 2-Drawer Bed Base 120x200':
    '811b35cb-d2c6-4694-97b2-29ba759b1536',
  'Colette Single 2-Drawer Bed Base 90x200':
    '78096415-53df-4c66-bb74-51aa9429d04d',
  'Colette King 4-Drawer Bed Base - 180x210 cm':
    'a601fd3e-d2fd-4f82-9f9f-632afc35ee0e',
  'Colette Super King 4-Drawer Bed Base 200x210 cm':
    '903f4bad-c1a5-4a1e-be67-9946f56593e1',

  'Colette King Bed Base - 180x210 cm': 'ecc66881-7f4d-4339-a4fe-eeee64de6f37',
  'Colette Velvet Queen Base - 155x205 cm':
    '6204f83a-6c0a-424d-93c7-2aec0835729d',
  'Colette Single Bed Base - 120x200 cm':
    '2ba3ba59-d24d-4eb0-9356-337a79982ca5',
  'Colette Single Bed Base - 90x200 cm': 'bc82df52-2242-419a-9bb3-facd0bc35b82',
  'Colette Super King Bed Base - 200x210 cm':
    '005ae538-c584-4876-884a-47f658d78231',

  'Stellar King Drawer Bed Base - 180x210 cm_Open':
    'c3d984ff-4578-4508-8832-2df0f65004df',
  'Stellar King Drawer Bed Base - 180x210 cm':
    'e0c6f22b-532e-4d5a-b3d4-6958a28fe911',
  'Stellar Queen Drawer Bed Base - 155x205 cm_Open':
    'bd450a35-c9df-40b8-a6eb-f620cd1173ed',
  'Stellar Queen Drawer Bed Base - 155x205 cm':
    '8c563194-10a3-4597-822f-0b5d24beae01',
  'Stellar Single Drawer Bed Base - 120x200 cm_Open':
    'f51e0456-dc24-4f14-b7d7-28275c25e7d7',
  'Stellar Single Drawer Bed Base - 120x200 cm':
    '41387c46-d568-4c0e-a7a6-7e7ac24cedc9',
  'Stellar King Hydraulic Bed Base - 180x210 cm_Open':
    'ba6c88fe-f57e-47a5-aa29-701bce43bf42',
  'Stellar King Hydraulic Bed Base - 180x210 cm':
    '6586392e-ccc2-47af-8082-109387aaea03',
  'Stellar Queen Hydraulic Bed Base - 155x205 cm_Open':
    'c85447f9-4834-4dab-9420-c3c40fd74689',
  'Stellar Queen Hydraulic Bed Base - 155x205 cm':
    '33dec5a0-3105-48ae-8efe-455659c0bf39',
  'Stellar Single Hydraulic Bed Base - 120x200 cm_Open':
    'baede145-b80e-4f4f-a15a-39a7cad1e817',
  'Stellar Single Hydraulic Bed Base - 120x200 cm':
    'c66f856f-5b40-4ff9-b0e4-fd2b8a139148',
  'Stellar King Bed Base - 180x210 cm': 'c3b59e69-fcbc-4184-9518-4eb25bb47ab3',
  'Stellar Queen Bed Base - 155x205 cm': 'fd775add-ff8b-49f0-aec8-221333ad7350',
  'Stellar Single Bed Base - 120x200 cm':
    '59b9d42d-9d21-4e56-9abb-79f4858e9873',
  'Estate King Bed Base with Drawer Storage - 180x200 cm':
    'db3a1bc9-2d05-41ac-9952-0bff1a5128d8',
  'Estate King Bed Base with Drawer Storage - 180x200 cm_Open':
    '5ea103e3-4f4d-4753-b583-7ef0200ead0b',

  // Headboard
  'Colette Stripe Super King Headboard - 200 cm':
    '1a6478df-a877-4cf9-9c98-ac1c70c54996',
  'Colette Straight Super King Headboard - 200 cm':
    'ed86c663-a4d9-43e0-8748-6450310a2d60',
  'Colette Stripe Single Headboard - 120 cm':
    '50d942d8-5f72-4b71-8c51-880e1b6de895',
  'Colette Stripe Queen Headboard - 155 cm':
    'da8a6db9-8a7b-4a77-a383-75a53b8f7321',
  'Colette Stripe King Headboard - 180 cm':
    '3f7e22af-cf38-4b8e-a293-28794362e6a1',
  'Colette Wing King Headboard - 180 cm':
    '9f9d482f-1bf1-4073-860d-db8ee74db7d9',
  'Colette Wing Queen Headboard - 155 cm':
    'b92157df-947c-4ea9-8671-178f7cd31596',
  'Colette Wing Single Headboard - 120 cm':
    '2767a45d-c712-494c-9940-887636df849b',
  'Colette Wing Super King Headboard - 200 cm':
    '18742162-730f-4393-87ef-ed1b7b103d57',
  'Colette Straight King Headboard - 180 cm':
    '050f29f5-7b6c-4b50-80c4-055936d78a74',
  'Colette Straight Queen Headboard - 155 cm':
    '4c60ba97-29b3-4986-9172-cd27880e0fc3',
  'Colette Straight Single Headboard - 90 cm':
    '935ca4de-87cf-47eb-8b82-8e74e992b336',
  'Colette Straight Single Headboard - 120 cm':
    '11a6d147-18c3-472b-90ec-fd2e0292a080', // item is not in table, but has a model
  'Colette Ray King Headboard - 180 cm': '121bc536-f6bc-44ae-9911-17f3217eeddd',
  'Colette Ray Queen Headboard - 155 cm':
    'e65f7bd2-cb64-4157-970a-400aee0bb6dc',
  'Colette Ray Single Headboard - 120 cm':
    'd3a3757e-b4ac-4c83-893b-3d7efef80fcd',
  'Colette Ray Super King Headboard - 200 cm':
    '0781dfd0-8bcd-49f7-9e2b-c50962a87f8c',
  'Colette Curve Single Headboard - 120 cm':
    '4bbbd731-cb3b-4264-b5bd-4af27e184d4d',
  'Colette Curve Queen Headboard - 155 cm':
    '1c9efe98-5f05-4858-9bb5-d8cb6d4b17f2',
  'Colette Curve Super King Headboard - 200 cm':
    '3aecf56b-bbdf-44a2-8cc6-00d09a1c0f86',
  'Colette Curve King Headboard - 180 cm':
    '81484b67-1065-493f-821d-fbc3569c28ad',
  'Stellar Ace King Headboard - 180 cm': '83f81502-f971-4149-82be-4bf1e0a9c21f',
  'Stellar Ace Queen Headboard - 155 cm':
    '3daf72bd-4d59-4583-be96-0385f48a0498',
  'Stellar Ace Single Headboard - 120 cm':
    'c37dea56-1b1d-4e95-bee7-02334d19143e',
  'Stellar Ark King Headboard - 180 cm': '454f00b0-8554-4c5e-8421-7d79797cce03',
  'Stellar Ark Queen Headboard - 155 cm':
    'abd8a2e3-830f-450b-8691-cb8935b8b179',
  'Stellar Ark Single Headboard - 120 cm':
    '40f10291-f35a-4b49-ad2e-ae8f930846d5',
  'Stellar Max King Headboard - 180 cm': '66611205-d4c3-41bc-b04b-a414ecda8a03',
  'Stellar Max Queen Headboard - 155 cm':
    '8e9cc2a1-becb-4d10-9f65-51c58a12a461',
  'Stellar Max Single Headboard - 120 cm':
    '9c51c851-06f0-4fc5-93ad-f48cbdfb66ef',
  'Stellar Neo King Headboard - 180 cm': '64c22fc8-9511-4e61-8f22-363c4ba3f317',
  'Stellar Neo Queen Headboard - 155 cm':
    '0b037aeb-ef46-4b47-8739-bafd73f15abe',
  'Stellar Neo Single Headboard - 120 cm':
    'c2edfccc-bb31-42de-af5e-7d42b1366c39',
  'Stellar Ora King Headboard - 180 cm': 'cb23480d-e8fd-497e-a521-48a2fec1290f',
  'Stellar Ora Queen Headboard - 155 cm':
    '5fa09f38-467e-4c26-8ce2-4e3286b46f92',
  'Stellar Ora Single Headboard - 120 cm':
    '626503dc-5b75-4069-92f2-15aa361c7cf7',
  'Stellar Vox King Headboard - 180 cm': 'e284b778-f0d0-423d-be9b-2ce8a040c62d',
  'Stellar Vox Queen Headboard - 155 cm':
    'd01e34a0-8f07-4618-9c92-dacb61724f0a',
  'Stellar Vox Single Headboard - 120 cm':
    '27fabf43-a27a-4594-99ab-9affb207e3f2',

  'Estate Olana King Headboard - 180 cm':
    '00fb0400-af1a-4ad6-8058-40550880ed67',
  'Estate Hudson King Headboard - 180 cm':
    'a127b309-dc37-48f7-8b22-4cc5729e231e',
  'Estate Alden King Headboard - 180 cm':
    '895fa35a-7519-4d2f-a0b5-4a45ffa0a6ab',
  None: '17371739-7ee1-4661-bc49-3a2f28c0fb3e',
  Headboard_None: '7845ce33-cb9a-4fb9-860f-420c851c8d7f',

  // Accessories
  Accessories: {
    Colette: {
      'King - 180x210 cm': '595cdee3-52ad-4799-a7f2-c088ec2e5f53',

      'Queen - 155x205 cm': '5769edbd-b084-412f-87cb-1c7cfc525377',

      'Single - 120x200 cm': '73c7c4a1-96c3-4b3a-9446-30e6679afe7d',

      'Single - 90x200 cm': '9d456037-a341-45e4-a17b-4ef3b766ab54',

      'Super King - 200x210 cm': '05fc1f5e-5de3-43e3-9e45-2d82e57bfaa1',
    },
    Stellar: {
      'King - 180x210 cm': '595cdee3-52ad-4799-a7f2-c088ec2e5f53',

      'Queen - 155x205 cm': '5769edbd-b084-412f-87cb-1c7cfc525377',

      'Single - 120x200 cm': '73c7c4a1-96c3-4b3a-9446-30e6679afe7d',
    },
    Estate: {
      'King - 180x200 cm': '51e3fe89-43dc-48bc-b145-7b7a103067aa',
    },
  },
  Accessories_None: '17371739-7ee1-4661-bc49-3a2f28c0fb3e',
};

export const sizesOrderedList = [
  'Single - 90x200 cm',
  'Single - 120x200 cm',
  'Queen - 155x205 cm',
  'King - 180x210 cm',
  'King - 180x200 cm',
  'Super King - 200x210 cm',
];

export const kingBedSizeName = sizesOrderedList[3];

// APIs

export const CONFIG_LIST_API = `${REACT_APP_THREEKIT_HOST}/api/configurations/?bearer_token=${REACT_APP_THREEKIT_AUTH_TOKEN}&orgId=${REACT_APP_THREEKIT_ORG}`;
