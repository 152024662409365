import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SECTIONS } from './constants';
import data from './data.json';
import { setPlayerReady } from './store/threekit';
import { saveUndoConfiguration } from './utils';

export const AppContext = React.createContext(null);

// immediately switching to config screen after adding an item has proven to be
// mostly a nuisance in testing, since you want to first add a bunch of
// different items simultaneously. This counter is used to only switch to the
// configure panel if there is a new selection and no pending items being added
// - ie the selection is almost certainly due to a user selection, not an
// addItem call.
let pendingItems = 0;

// class AppProvider extends React.Component {
const AppProvider = ({ children }) => {
  const dispatch = useDispatch();
  const isMounted = React.useRef(true);
  const [section, setSection] = useState(SECTIONS.addItem);
  const [selected, setSelected] = useState(null);
  const { showDimensions, category } = useSelector((s) => s.threekit);
  useEffect(() => {
    window.threekit
      .init({
        el: 'player-root',
        onSelectionChange: handleItemSelection,
      })
      .then(() => {
        //expose these directly on window for dev convenience
        window.api = window.threekit.api;
        window.configurator = window.threekit.configurator;
        window.threekit.configurator.showMeasurement(showDimensions, category);
      })
      .then(() => {
        // fix error case for product edit configuration + resume configuration
        if (!!window.threekit.editConfigId) {
          // window.threekit.api.player.translator.updating = true;
          window.threekit.configurator.reset({
            configId: window.threekit.editConfigId,
          });
        }
      })
      .finally(() => {
        if (!!window.threekit.editConfigId) {
          // window.threekit.api.player.translator.updating = false;
          window.threekit.editConfigId = '';
        }
        dispatch(setPlayerReady(true));
      });
    return () => {
      isMounted.current = false;
    };
  }, []);
  const handleSetSection = (newSection) => setSection(newSection);

  const handleAddItem = async (item) => {
    pendingItems++;
    const itemId = await window.threekit.configurator.addItem(item);
    pendingItems--;
    return itemId;
  };

  const handleItemSelection = (idxs, isDragged = false) => {
    if (!isMounted.current) return;
    const firstSelectedItemId =
      idxs === undefined || idxs.length === 0 ? null : idxs[0];

    setSelected(firstSelectedItemId);
    setSection(
      firstSelectedItemId && !pendingItems
        ? SECTIONS.configureItem
        : SECTIONS.addItem
    );
    if (isDragged) saveUndoConfiguration();
  };

  const handleGetItem = (idx = selected) => {
    const item = idx && window.threekit.configurator.getItems().get(idx);

    if (item) return { ...item, id: idx };
    else return null;
  };

  const handleDeleteItems = (idxs) => {
    if (!idxs || !idxs.length) return;
    window.threekit.configurator.removeItems(idxs);
    setSection(SECTIONS.addItem);
  };

  const handleSetFloor = (val) => window.threekit.configurator.setFloor(val);

  const handleRotate = (deg) =>
    window.threekit.configurator.rotateItems([selected], deg);

  const value = {
    data,
    selected,
    section,
    handleSetSection,
    handleAddItem,
    handleSetFloor,
    handleRotate,
    handleGetItem,
    handleDeleteItems,
  };
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default AppProvider;
