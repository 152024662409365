import styled from 'styled-components';
import IconSVG from '../Icon/IconSVG';

export const MenuWrapper = styled.div`
  position: absolute;
  background-color: #fff;
  z-index: 99999;
  border: 1px solid #bdbbbb;
  border-radius: 3px;
  font-size: 14px;
`;

export const ArrowIcon = styled(IconSVG)`
  width: 10px;
  margin: 0 10px;
`;

export const FlagIcon = styled(IconSVG)`
  width: 17px;
  height: 17px;
`;

export const SelectedValue = styled.div`
  cursor: pointer;
`;
