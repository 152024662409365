import React from 'react';
import { Wrapper } from './BedTabs.styles';
import { Tabs } from 'antd';
import ProductBlock from '../ProductBlock/ProductBlock';
import { useDispatch, useSelector } from 'react-redux';
import { setProductType } from '../../store/threekit';

export default function BedTabs({ t, colorList }) {
  const { TabPane } = Tabs;
  const dispatch = useDispatch();
  const { products, category, collection, size, type } = useSelector(
    (s) => s.threekit
  );
  const types = Object.keys(products[category]?.[collection] || {}).sort();

  const setBedType = (type) => {
    dispatch(setProductType(type));
  };

  // function tabClick() {
  //   document.querySelector('.tettt').classList.toggle('co');
  // }

  // const tabClick = event => {
  //   event.currentTarget.classList.toggle('bg-salmon');
  // }

  return (
    <>
      <Wrapper>
        <h5 className="all-title">
          <span>{t(`common.collectionsSimple.${collection}`)}</span>
          &nbsp;
          <span>{t(`header.categories.${category}`)}</span>
          &nbsp;
          <span>{t(`common.sizes.${size}`)}</span>
        </h5>
        <div id="tabs-size">
          <Tabs activeKey={type} onChange={(tab) => setBedType(tab)}>
            {types.map((typeName) => (
              <TabPane
                tab={t(`sidebar.productType.${typeName}`)}
                key={typeName}
                className="bed_tabs"
              >
                <ProductBlock t={t} colorList={colorList} />
              </TabPane>
            ))}
          </Tabs>
        </div>
      </Wrapper>
    </>
  );
}
