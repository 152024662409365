import styled from 'styled-components';

export const SizeBlock = styled.div`
  .size-block li {
    display: inline-block;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    font-weight: 400;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
    margin: 0 2% 2% 0;
    width: 49%;
    cursor: pointer;
  }

  .size-block ul {
    padding: 0;
  }

  .size-block li.active_size {
    background: #e4e0ee;
    border-color: #7665a0;
  }
`;
