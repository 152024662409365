import styled from 'styled-components';

export const PdfWrapper = styled.div`
  * {
    margin: 0;
    padding: 0;
    font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
    line-height: inherit;
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }

  .product_price {
    display: flex;
    flex-direction: column;
  }

  .combine_price_mobiles {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .combine_list_price_mobiles {
    font-weight: 800;
    color: #afb0b1;
    display: flex;
    font-size: 18px;
    margin: 0.4rem 0 0.4rem;
    justify-content: start;
  }
  span.f_ar_price {
    margin: 0 0.3rem;
  }

  img {
    max-width: 100%;
  }
  .collapse {
    margin: 0;
    padding: 0;
  }
  body {
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: none;
    width: 100% !important;
    height: 100%;
  }

  p {
    margin: 0;
  }

  table.body-wrap {
    width: 100%;
  }

  .t-logo {
    max-width: 90%;
    width: 100%;
    margin-bottom: 30px;
  }

  .container {
    display: block;
    max-width: 800px;
    margin: 30px auto; /* makes it centered */
    clear: both;
  }

  .column {
    float: left;
  }

  .column.column-left {
    width: 100px;
    position: relative;
    background-size: cover;
    background-repeat: repeat;
  }

  .column-left img {
    height: 100%;
    width: 100%;
  }

  .column.column-right {
    max-width: 705px;
    width: 100%;
  }
  .full-product {
    width: 100%;
    margin: 40px 0 50px;
  }
  .column tr td {
    padding: 15px 15px 0;
  }
  .column tr td.padding-remove {
    padding: 0;
  }

  .column-wrap {
    padding: 0 !important;
    margin: 0 auto;
    max-width: 800px !important;
    display: flex;
  }
  .column table {
    width: 100%;
  }

  .main-title {
    margin: 0px;
    word-wrap: break-word;
    padding: 20px 0;
    border-bottom: 2px solid #ededed;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #3f4448;
  }

  .border-none {
    border-bottom: none;
  }

  .info p {
    font-weight: 500;
    letter-spacing: 0.5px;
    font-size: 20px;
    line-height: 24px;
    color: #3f4448;
  }

  .info {
    margin: 30px 0;
  }
  .inf {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
  }

  .inf .single_barcode {
    max-width: 310px;
  }

  .inf h2 {
    margin: 0px;
    text-align: left;
    word-wrap: break-word;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #3f4448;
  }
  .inf span {
    font-weight: 700;
    font-size: 30px;
    color: #7665a0;
    margin: 6px 0 0 0;
    display: block;
  }

  .small-bar-code {
    max-width: 310px;
  }

  .list-grid {
    display: flex;
    justify-content: space-between;
    padding: 10px 0 15px;
    font-size: 16px;
    border-bottom: 2px solid #ededed;
    color: #3f4448;
    font-weight: 600;
  }

  .product-list {
    display: flex;
    justify-content: space-between;
    padding: 30px 0;
    border-bottom: 2px solid #ededed;
  }

  .t-product-info p {
    font-size: 15px;
    color: #5b6065;
    font-weight: 700;
  }

  .t-product-info h5 {
    font-size: 20px;
    color: #3e454a;
  }
  .t-main-price {
    color: #afb0b1;
  }

  .line-through {
    text-decoration: line-through;
  }

  .t-product-info span {
    font-weight: 900;
  }
  .t-sale-price {
    color: #d21818;
  }

  .product-list img.small-bar-code {
    max-width: 210px;
  }

  .grand-total {
    padding: 20px 0;
    border-bottom: 2px solid #ededed;
    color: #adadad;
    font-weight: 500;
    font-size: 16px;
  }
  .grand-total span {
    font-size: 25px;
    font-weight: 600;
    margin: 0.2rem;
    color: #3d444d;
  }

  .grand-total .t-sale-price {
    margin: 10px 0px 0 10px;
    color: #d21818;
  }

  .t-footer {
    border: none;
    padding: 14px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: unset;
  }
  .t-footer > div {
    width: 50%;
  }

  .footer-text {
    margin-bottom: 5px;
  }
  .grand-footer-price {
    text-align: right;
  }

  /* Be sure to place a .clear element after each set of columns, just to be safe */
  .clear {
    display: block;
    clear: both;
  }

  @media only screen and (max-width: 600px) {
    .column.column-left {
      display: none;
    }

    .main-title {
      text-align: center;
      font-size: 25px;
    }

    .product-list {
      flex-flow: wrap;
      padding: 25px 0;
    }

    .product-list img.small-bar-code {
      max-width: 110px;
    }

    .t-product-info h5 {
      font-size: 16px;
    }
    .t-product-info p {
      font-size: 13px;
    }

    .t-product-info span {
      font-size: 15px;
    }

    .info p {
      font-size: 18px;
    }

    .inf {
      flex-flow: wrap;
      justify-content: center;
    }
    .inf span {
      margin: 6px 0 10px 0;
    }

    .inf h2 {
      text-align: center;
    }
    .t-footer > div {
      width: 100%;
      margin-bottom: 14px;
    }
    .grand-total,
    .t-footer {
      text-align: center;
      flex-direction: column-reverse;
    }
    .t-logo {
      max-width: 100%;
    }
  }

  @media print {
    .body-wrap {
      background-color: #fff;
      height: 100%;
      width: 100%;
      margin: 0;
      padding: 0px 0px 0px 15px;
      font-size: 14px;
      line-height: 18px;
    }
    * {
      -webkit-print-color-adjust: exact !important;
      color-adjust: exact !important;
    }
  }
`;
