import React, { Children, cloneElement } from 'react';

const CardGroup = (props) => {
  const { children, onChange, value, error, name } = props;
  const renderChildren = () => {
    return Children.map(children, (element) => {
      const { type, props: _props } = element;
      if (typeof type !== 'function') {
        return;
      }

      const isChecked = +value === _props.value;
      const newProps = {
        ..._props,
        name,
        error,
        checked: isChecked,
        onChange: onChange,
      };
      return cloneElement(element, newProps);
    });
  };

  return <div className={props.className}>{renderChildren()}</div>;
};

export default CardGroup;
