import { setConfigOnModel } from '.';
import { attributeIds, PRODUCT_COLOR_IDS } from '../constants';
import store from '../store';
import {
  resetSummary,
  setPlayerReady,
  setUndoConfigArray,
} from '../store/threekit';

export const setBedAttributes = async ({ newColor, newSize } = {}) => {
  let newSummary = [];
  const reduxStore = store.getState().threekit;
  const {
    summary,
    products,
    collection,
    undoConfigArray,
    hasAccessories,
    addOnsSummary,
    color: rawColor,
    size: rawSize,
  } = reduxStore;

  const color = newColor || rawColor;
  const size = newSize || rawSize;

  if (size === 'Sofa') return;
  let typeConfig = {
    Bedbase: { assetId: attributeIds.Bedbase_None },
    Headboard: { assetId: attributeIds.Headboard_None },
    Accessories: { assetId: attributeIds.Accessories_None },
  };

  try {
    store.dispatch(setPlayerReady(false));
    let modelId;

    const [firstPlayerModel] = await window.threekit.configurator
      .getItems()
      .values();
    modelId = firstPlayerModel?.id;
    if (!modelId) return;

    summary.forEach((summaryItem) => {
      const { category, type, short } = summaryItem;
      // set new product to summary
      const newProductItem =
        products[category][collection][type][size]?.[short] || {};

      const { en, ar, ...colors } = newProductItem;

      const colorObj = colors[color] || {};

      const { price = [], dPrice = [], sku } = colorObj;

      const key = attributeIds[type]; // Bedbase or Headboard
      const newProductObj = {
        ...summaryItem,
        modelId,
        en,
        ar,
        short,
        sku,
        price,
        dPrice,
        size,
        color,
        qty: 1,
        key: en,
        productType: type,
      };
      // set bed model id or None if not valid
      const assetId = !!en ? attributeIds[en] : attributeIds[`${key}_None`];
      typeConfig = {
        ...typeConfig,
        [key]: { ...newProductObj, assetId },
      };
      if (!!en) newSummary.push({ ...newProductObj });
    });

    if (newColor || newSize) {
      const newAddOnsSummary = addOnsSummary;
      store.dispatch(resetSummary({ newSummary, newAddOnsSummary }));
    }
    const accessoriesId = attributeIds.Accessories[collection][size];

    const configuration = {
      ...typeConfig,
      // ...(hasAccessories && { Accessories: { assetId: accessoriesId } }),
      Color: {
        assetId: PRODUCT_COLOR_IDS[collection]?.[color],
      },
    };

    await setConfigOnModel(modelId, configuration);
    if (hasAccessories) {
      await setConfigOnModel(modelId, {
        Accessories: { assetId: accessoriesId },
      });
    }

    window.threekit.configurator.configureItems(modelId, {
      configuration,
      type: 'Bed Collection',
      key: 'Bed Model',
      qty: 1,
      productType: 'Bed Type',
      color,
    });

    const configId = await window.threekit.configurator.saveConfiguration({
      // designId,
      collection,
      type: 'Bed Collection',
      color: newColor,
    });
    const newPlayerConfigs = [...undoConfigArray, configId];
    store.dispatch(setUndoConfigArray(newPlayerConfigs));

    store.dispatch(setPlayerReady(true));
  } catch (err) {
    console.log('Failed to add a product', err);
    store.dispatch(setPlayerReady(true));
  }
};
