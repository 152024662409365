import styled from 'styled-components';

export const Wrapper = styled.div`
  .overlay-images {
    position: absolute;
    top: 15px;
    z-index: 1;
    right: 40px;
  }

  .overlay-images img {
    margin: 0 7px;
    cursor: pointer;
    padding: 10px;
  }

  .overlay-images .ant-dropdown-open .light_color {
    background: #fafafa;
    margin: 0 7px 0 0;
  }

  .plyer-bottom-icons {
    bottom: 0px;
    top: unset;
    right: 0;
    left: 0;
    text-align: center;
    margin: 0 2px;
    padding: 9px;
    position: absolute;
    max-width: 290px;
    margin: auto;
  }
  .plyer-bottom-icons img {
    margin: 0px 0px 10px;
    padding: 0 6px;
  }
`;
