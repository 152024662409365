import React, { useState } from 'react';
import axios from 'axios';
import media from '../../assets';
import { Wrapper } from './login.styles';
import { rowValueApi, storeCodeColumn } from '../../constants';
import { useAlert } from 'react-alert';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from '../../store/threekit';
import i18n from '../../i18next';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';
import { useIsAuthenticated } from '@azure/msal-react';
import { Spin } from 'antd';

function Login({ history, t }) {
  //MSAL Hook Declaration
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();

  if (isAuthenticated) {
    //Request for Access Token to LM Server
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(request)
      .then((response) => {
        localStorage.setItem('app-access-token', response.accessToken);
        //localStorage.setItem('app-refresh-token', response.refreshToken);
      })
      .catch((e) => {
        instance.acquireTokenPopup(request).then((response) => {
          localStorage.setItem('app-access-token', response.accessToken);
          //localStorage.setItem('app-refresh-token', response.refreshToken);
        });
      })
      .finally(() => {
        console.log('User Login Successfully.');
        history.push('/configuratorarea');
      });
  }

  const alert = useAlert();
  const dispatch = useDispatch();
  const { language } = useSelector((s) => s.threekit);
  const [token, setToken] = useState('');

  const handleLanguageChange = () => {
    const newLanguage = language === 'en' ? 'ar' : 'en';
    i18n.changeLanguage(newLanguage);
    dispatch(setLanguage(newLanguage));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    e.persist();

    const where = encodeURIComponent(
      JSON.stringify({
        [storeCodeColumn]: {
          column: storeCodeColumn,
          comparator: '=',
          value: parseInt(e.target.storeCode.value),
        },
      })
    );

    try {
      const rowsResult = await axios.get(`${rowValueApi}&where=${where}`);
      console.log(`Row = `, rowsResult);
      if (rowsResult.status === 200) {
        localStorage.setItem(
          'store-code',
          JSON.stringify(rowsResult?.data?.value)
        );
        alert.success('Store Code Valid');

        setTimeout(() => {
          instance.loginRedirect(loginRequest).catch((e) => {
            console.error(e);
          });
        }, 1000);
      }
    } catch (err) {
      alert.error('Store Code Invalid!');
      console.log('Err = ', err);
    }
  };

  return (
    <>
      <Wrapper className="all-wrapper">
        <div className="d-flex flex-column flex-root full-height bgs-white">
          <div className="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white">
            <div className="login-aside d-flex flex-column flex-row-auto">
              <div
                className="aside-img d-flex flex-row-fluid bgi-no-repeat"
                style={{ backgroundImage: `url(${media.banner})` }}
              ></div>
            </div>
            <div className="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden px-3 mxc-auto">
              <div className="d-flex flex-column-fluid flex-center">
                <div className="login-form login-signin">
                  <div className="logo">
                    <img src={media[`${language}_logo`]} alt="Logo" />
                  </div>
                  <form className="login-form" onSubmit={handleLogin}>
                    <div className="form-group first mb-4">
                      <label htmlFor="username">{t('login.storeCode')}</label>
                      <input
                        id="storeCode"
                        className="cu-form-control"
                        type="text"
                        name="storeCode"
                        placeholder={t('login.enterStoreCode')}
                      />
                      <input
                        type="submit"
                        className="btn-block-login btn-block"
                        value={t('login.signIn')}
                      />
                    </div>

                    <div className="align-items-center d-flex justify-content-center switch-language">
                      <span> {t('login.switchTo')} </span>
                      <span onClick={handleLanguageChange}>
                        {t('common.language')}
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
}

export default React.memo(Login);
