import React from 'react';
import { OptionWrapper, OptionItem } from './DropDownOption.styled';
import { FlagIcon } from './DropDown.styled';
import { useSelector } from 'react-redux';

const DropDownOption = ({ handleChangeDropdown, value, t, country }) => {
  const { language } = useSelector((s) => s.threekit);
  const isSelected = country === value;
  return (
    <OptionWrapper
      language={language}
      onClick={() => handleChangeDropdown(value)}
      isSelected={isSelected}
    >
      <FlagIcon name={value} customDimensions />
      <OptionItem>{t(`header.countries.${value}`)}</OptionItem>
    </OptionWrapper>
  );
};

export default DropDownOption;
