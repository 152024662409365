import { Modal, Button } from 'antd';
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  saveCurrentConfigId,
  setPlayerSnapshot,
  setProceedState,
  resetSummary,
  setFromSummary,
  setDesignId,
  setUndoConfigArray,
  setCurrentCollection,
  setCurrentProductCategory,
  setAccessories,
  setModelOpen,
} from '../../store/threekit';
import FormattedPrice from '../common/FormattedPrice';
import { Link } from 'react-router-dom';
import { ProductPrice } from '../ProductCard/ProductCard.styled';
import { Wrapper } from './footer.styles';
import { countryList, currencyCoutryList, currencyList } from '../../constants';
import { useHistory, useLocation } from 'react-router-dom';
import { AppContext } from '../../context';
import { getTotalPriceAndQty } from '../../utils';

export default function Footer({ t }) {
  const context = useContext(AppContext) || {};
  const { selected } = context;
  const [isModalSaveVisible, setModalSaveVisible] = useState(false);
  const {
    designId,
    summary,
    country,
    addOnsSummary,
    collection,
    type,
    fromSummary,
    undoConfigArray,
    category,
  } = useSelector((s) => s.threekit);

  const dispatch = useDispatch();
  const handleOkSave = () => {
    dispatch(setDesignId());
    setModalSaveVisible(false);
  };

  const handleCancelSave = () => {
    dispatch(setDesignId());
    setModalSaveVisible(false);
  };

  //Start Over Button Start
  const [isModalStartOver, setModalStartOver] = useState(false);
  const handleCancelStar = () => {
    setModalStartOver(false);
  };
  const startOver = async () => {
    setModalStartOver(true);
  };
  //Start Over Button End
  const saveConfig = async () => {
    if (!Object.keys(summary).length) return;
    const configId = await window.threekit.configurator.saveConfiguration({
      designId,
      // collection,
      // type,
      // hasDesign: true,
    }); // save design for future resume
    dispatch(saveCurrentConfigId(configId));
    setModalSaveVisible(true);
  };

  //Summary Left Panel Control
  function summaryleftPannel() {
    if (!!undoConfigArray.length)
      window.threekit.editConfigId = undoConfigArray.at(-1);
    dispatch(setProceedState(1));
    dispatch(setModelOpen(false)); // reset open model state
    dispatch(setAccessories(false)); // reset model accessories  state
    window.threekit.configurator.clearUpAttachmentPoints();

    if (!!selected)
      window.threekit.configurator.deselectItems([selected], true); // deselect before making a snapshot, isUnmounted = true
    window.threekit.api.snapshotAsync({
        size: { width: 955, height: 507 },
      })
    .then((data) => {
      dispatch(setPlayerSnapshot(data));
    });
  }
  const currency = t(`common.currencies.${currencyCoutryList[country]}`);
  const { totalPrice, totalDPrice, discountedPrice } = getTotalPriceAndQty();

  /*Start Over*/
  const history = useHistory();
  async function startOverRedirection(key) {
    if (key === true) {
      setModalStartOver(false);
      await window.threekit.configurator.reset();
      window.threekit.editConfigId = '';
      dispatch(setUndoConfigArray([]));
      dispatch(resetSummary());
      dispatch(setDesignId());
      dispatch(setFromSummary(1));
      if (category === 'Bed') {
        dispatch(setCurrentProductCategory('Sofa'));
        dispatch(setCurrentCollection('Eterno'));
      }
      history.push('/configuratorArea');
    } else {
      setModalStartOver(false);
    }
  }

  const goToAddons = () => {
    dispatch(setFromSummary(2));
  };

  const goToConfigurator = () => {
    dispatch(setFromSummary(1));
    history.push('/configuratorArea');
  };

  const location = useLocation();

  return (
    <>
      <Wrapper className="all-wrapper">
        <div className="footer">
          <div className="row w-100 m-0">
            <div className="col-sm-6 d-flex align-items-center footer-buttons footer-price justify-content-center justify-content-lg-start">
              <ProductPrice>
                <div className="combine_price_mobiles">
                  <span>{t('footer.total')}</span>
                  <span className="without_sale_price reverse">
                    <span className="f_ar_price">{currency} </span>
                    <FormattedPrice
                      price={totalPrice}
                      dPrice={totalDPrice}
                    />{' '}
                  </span>
                  {totalDPrice > 0 ? (
                    <span className="sale-price reverse">
                      <span className="sale-prices f_ar_price">
                        {currency}{' '}
                      </span>
                      {(totalPrice - discountedPrice).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </ProductPrice>
            </div>

            <div className="col-sm-6 d-flex footer-buttons justify-content-lg-end">
              {location.pathname === '/itemsummary' ? (
                fromSummary >= 3 ? (
                  <>
                    <button className="footer-button" onClick={goToAddons}>
                      {t('footer.goBack')}
                    </button>

                    <button
                      className="footer-button"
                      onClick={() => startOver()}
                    >
                      {t('footer.startOver')}
                    </button>
                  </>
                ) : (
                  <>
                    {(fromSummary > 2 || fromSummary < 2) && (
                      <button
                        className={
                          !summary.length
                            ? 'footer-button disabled'
                            : 'footer-button'
                        }
                        onClick={() => saveConfig()}
                      >
                        {t('footer.saveForLater')}
                      </button>
                    )}
                    {fromSummary === 2 && (
                      <button
                        className="footer-button"
                        onClick={goToConfigurator}
                      >
                        {t('footer.edit')}
                      </button>
                    )}
                    {!summary.length ? (
                      <Link className="footer-button disabled">
                        {t('footer.proceed')}
                      </Link>
                    ) : (
                      <Link
                        className="footer-button"
                        to={{
                          pathname: '/itemsummary',
                        }}
                        onClick={summaryleftPannel}
                      >
                        {t('footer.proceed')}
                      </Link>
                    )}
                  </>
                )
              ) : (
                <>
                  {(fromSummary > 2 || fromSummary < 2) && (
                    <button
                      className={
                        !summary.length
                          ? 'footer-button disabled'
                          : 'footer-button'
                      }
                      onClick={() => saveConfig()}
                    >
                      {t('footer.saveForLater')}
                    </button>
                  )}
                  {fromSummary === 2 && (
                    <button
                      className="footer-button"
                      onClick={goToConfigurator}
                    >
                      Edit Configuration
                    </button>
                  )}
                  {!summary.length ? (
                    <span className="footer-button disabled">
                      {t('footer.proceed')}
                    </span>
                  ) : (
                    <Link
                      className="footer-button"
                      to={{
                        pathname: '/itemsummary',
                      }}
                      onClick={summaryleftPannel}
                    >
                      {t('footer.proceed')}
                    </Link>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        {/*Start Over Modal Start*/}
        <Modal
          visible={isModalStartOver}
          onCancel={handleCancelStar}
          centered
          footer={null}
        >
          <div className="save-later-modal">
            <h3>{t('modal.thisWillReset')}</h3>
            <p className="title">{t('modal.doYouReally')}</p>
            <Button onClick={() => startOverRedirection(false)}>
              {t('modal.no')}
            </Button>
            <Button onClick={() => startOverRedirection(true)}>
              {t('modal.yes')}
            </Button>
          </div>
        </Modal>
        {/*Start Over Modal End*/}

        <Modal
          visible={isModalSaveVisible}
          centered
          onOk={() => {
            setModalSaveVisible(false);
          }}
          onCancel={handleCancelSave}
          footer={null}
        >
          <div className="save-modal">
            <h3>{t('modal.savedDesign')}</h3>
            <p className="title">
              {t('modal.pleaseNote')}

              <span className="design-id">
                {t('modal.designId', { designId })}
              </span>
              {t('modal.resumeLater')}
            </p>
            <Button type="primary" htmlType="submit" onClick={handleOkSave}>
              Ok
            </Button>
          </div>
        </Modal>
      </Wrapper>
    </>
  );
}
