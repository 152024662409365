import styled from 'styled-components';

export const Wrapper = styled.div`
    .bgs-white {
        background: #fff;
    }

    .logo img {
        width: 100%;
        margin: 0 auto 60px auto;
        max-width: 300px;
        display: block;
    }
    
    .login-form label {
        color: #212121;
        font-weight: 700;
        font-size: 15px;
    }
    
    .login-form .cu-form-control {
        background: #FFFFFF;
        border: 1px solid #C8C9C6;
        box-sizing: border-box;
        border-radius: 2px;
        height: 48px;
        width: 100%;
        padding: 0px 16px;
        outline: none;
    }

    .btn-block-login {
        display: block;
        width: 100%;
        background: #7665a0;
        padding: 14px;
        border: none;
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        font-weight: 500;
        margin-top: 40px;
        border-radius: 3px;
    }

    .login-form label {
        margin-bottom: 7px;
    }
    .switch-language span {
        margin: 0;
        font-weight: 400;
        cursor:pointer;
        color: #9c9c9e;
    }
    .switch-language span:last-child {
        color: #212121;
        margin: 0 6px;
    }
    .switch-language select.form-control {
        height: auto !important;
        border: none !important;
        font-weight: 400;
        letter-spacing: 0.5px;
        padding: 0 0 0 4px;
        color: #212121;
        cursor: pointer;
        background-color: transparent
        outline: none;
        box-shadow: none;
    }

    .aside-img {
        background-size: cover;
        background-repeat: no-repeat;
    }
`;
