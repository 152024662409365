import React, { useRef } from 'react';
import media from '../../assets';
import { useTranslation } from 'react-i18next';
import { PdfWrapper } from './pdf.styles';
import { useSelector } from 'react-redux';
import { countryList, currencyCoutryList, currencyList } from '../../constants';
import Barcode from 'react-barcode';
import store from '../../store';
import { summaryToObj } from '../../utils';
import FormattedPrice from '../common/FormattedPrice';

export const HtmlPdf = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const reduxStore = store.getState().threekit;
  const {
    language,
    summary: summaryArr,
    country,
    addOnsSummary,
  } = useSelector((s) => s.threekit);
  const countryIndex = countryList.indexOf(country);
  const currency = t(`common.currencies.${currencyCoutryList[country]}`);
  //Get Summary Product
  const summary = summaryToObj(summaryArr);
  const skuList = Object.keys(summary);
  //const skuList = Object.keys(summary).length ? Object.keys(summary) : [];
  //Get Addon Products
  const addonsList = Object.keys(addOnsSummary).length
    ? Object.keys(addOnsSummary)
    : [];

  //Get Total Price
  const summaryArray = Object.values(summary);
  const addOnsSummaryArray = Object.values(addOnsSummary);
  let comnbinePriceArr = [];
  comnbinePriceArr = [...summaryArray, ...addOnsSummaryArray];
  let totalPrice = 0;
  let totalDPrice = 0;
  let discountedPrice = 0;
  comnbinePriceArr.forEach(({ price, dPrice, qty }) => {
    const tPirce = price[countryIndex] * qty;
    const dPriceOfProduct = dPrice.length
      ? (dPrice[countryIndex] || 0) * qty
      : 0;
    totalPrice = totalPrice + tPirce;
    totalDPrice = totalDPrice + dPriceOfProduct;
    if (dPriceOfProduct != 0) {
      discountedPrice = discountedPrice + tPirce - dPriceOfProduct;
    }
  });

  return (
    <PdfWrapper ref={ref}>
      <table
        className="body-wrap"
        style={{ direction: language === 'en' ? '' : 'rtl' }}
      >
        <tbody>
          <tr>
            <td className="container" bgcolor="#FFFFFF">
              <div className="column-wrap">
                <div className="column column-left">
                  <img src={media.pdf_bg} alt="Bg" />
                </div>
                <div className="column column-right">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <table>
                            <tbody>
                              <tr>
                                <td className="padding-remove">
                                  <table
                                    width="100%"
                                    cellPadding="0"
                                    cellSpacing="0"
                                    border="0"
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          className="padding-remove"
                                          align="center"
                                        >
                                          <img
                                            src={
                                              language === 'en'
                                                ? media.en_pdf_logo
                                                : media.ar_pdf_logo
                                            }
                                            alt="Logo"
                                            className="t-logo"
                                            style={{ textAlign: 'center' }}
                                          />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table>
                            <tbody>
                              <tr>
                                <td className="padding-remove">
                                  <table
                                    width="100%"
                                    cellPadding="0"
                                    cellSpacing="0"
                                    border="0"
                                  >
                                    <tbody>
                                      <tr>
                                        <td className="padding-remove">
                                          <h1 className="main-title">
                                            {t('pdf.modularPlanner')}
                                          </h1>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table>
                            <tbody>
                              <tr>
                                <td className="padding-remove">
                                  <table
                                    width="100%"
                                    cellPadding="0"
                                    cellSpacing="0"
                                    border="0"
                                  >
                                    <tbody>
                                      <tr>
                                        <td className="padding-remove">
                                          <div className="info">
                                            <p>
                                              {t('pdf.dear')}{' '}
                                              {props.customerName},
                                            </p>
                                            <p>&nbsp;</p>
                                            <p>{t('pdf.thankMsg')}</p>
                                            <p>&nbsp;</p>
                                            <p>{t('pdf.visitMsg')}</p>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="padding-remove">
                                          <div className="inf">
                                            <div>
                                              <h2>{t('pdf.designId')}</h2>
                                              <span>{reduxStore.designId}</span>
                                            </div>
                                            <div>
                                              <Barcode
                                                className="cv single_barcode"
                                                value={reduxStore.designId.toString()}
                                                width={2}
                                                height={40}
                                              />
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table
                            role="presentation"
                            cellPadding="0"
                            cellSpacing="0"
                            width="100%"
                            border="0"
                          >
                            <tbody>
                              <tr>
                                <td className="padding-remove full-product-td">
                                  <img
                                    className="full-product"
                                    align="center"
                                    border="0"
                                    src={reduxStore.playerSnapshot}
                                    alt="Product img"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table>
                            <tbody>
                              <tr>
                                <td className="padding-remove">
                                  <table>
                                    <tbody>
                                      <tr>
                                        <td className="padding-remove">
                                          <h1 className="main-title">
                                            {t('pdf.cmponentDetails')}
                                          </h1>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table>
                            <tbody>
                              <tr>
                                <td className="padding-remove">
                                  <table
                                    width="100%"
                                    cellPadding="0"
                                    cellSpacing="0"
                                    border="0"
                                  >
                                    <tbody>
                                      <tr>
                                        <td className="padding-remove">
                                          <div className="list-grid">
                                            <div>{t('pdf.itemList')}</div>
                                            <div>{t('pdf.barCode')}</div>
                                          </div>
                                        </td>
                                      </tr>
                                      {/*Product Listing*/}
                                      {skuList.map((sku, i) => {
                                        const summaryItem = summary[sku];
                                        const countryDiscoutedPrice =
                                          summaryItem.dPrice[countryIndex] || 0;

                                        const countryPrice =
                                          summaryItem.price[countryIndex];
                                        return (
                                          <tr key={i.toString()}>
                                            <td className="padding-remove">
                                              <div className="product-list">
                                                <div className="t-product-info">
                                                  <h5>
                                                    {summaryItem[language]}
                                                  </h5>

                                                  <div className="combine_list_price_mobiles">
                                                    <div className="product_price">
                                                      {!!countryDiscoutedPrice &&
                                                        countryPrice !==
                                                          countryDiscoutedPrice && (
                                                          <span dir="ltr">
                                                            <span
                                                              dir="ltr"
                                                              className={`${
                                                                !!countryDiscoutedPrice
                                                                  ? 't-sale-price'
                                                                  : ''
                                                              } f_ar_price`}
                                                            >
                                                              {currency}
                                                            </span>
                                                            <FormattedPrice
                                                              dPrice={
                                                                countryDiscoutedPrice
                                                              }
                                                            />
                                                          </span>
                                                        )}
                                                      <span dir="ltr">
                                                        <span
                                                          dir="ltr"
                                                          className="f_ar_price"
                                                        >
                                                          {currency}
                                                        </span>
                                                        <FormattedPrice
                                                          price={countryPrice}
                                                          dPrice={
                                                            countryDiscoutedPrice
                                                          }
                                                        />
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <p>
                                                    {t('pdf.quantity')}:
                                                    {summaryItem.qty}
                                                  </p>
                                                </div>
                                                <div>
                                                  <Barcode
                                                    className="small-bar-code"
                                                    value={summaryItem.sku.toString()}
                                                    width={2}
                                                    height={40}
                                                  />
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                      {/*Addon Start*/}
                                      {!!addonsList.length && (
                                        <tr>
                                          <td className="padding-remove">
                                            <h2 className="main-title">
                                              {t('pdf.addons')}
                                            </h2>
                                          </td>
                                        </tr>
                                      )}
                                      {addonsList.map((sku, i) => {
                                        const addonsSummaryItem =
                                          addOnsSummary[sku];
                                        const { dPrice, price, qty } =
                                          addonsSummaryItem;
                                        return (
                                          <tr key={i.toString()}>
                                            <td className="padding-remove">
                                              <div className="product-list">
                                                <div className="t-product-info">
                                                  <h5>
                                                    {
                                                      addonsSummaryItem[
                                                        language
                                                      ]
                                                    }
                                                  </h5>

                                                  <div className="combine_list_price_mobiles">
                                                    <div dir="ltr">
                                                      {!!dPrice[
                                                        countryIndex
                                                      ] && (
                                                        <div>
                                                          <span
                                                            dir="ltr"
                                                            className="t-sale-price f_ar_price"
                                                          >
                                                            {currency}{' '}
                                                          </span>

                                                          <span
                                                            dir="ltr"
                                                            className="t-sale-price t-main-price"
                                                          >
                                                            {dPrice[
                                                              countryIndex
                                                            ].toLocaleString(
                                                              'en-US',
                                                              {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                              }
                                                            )}
                                                          </span>
                                                        </div>
                                                      )}
                                                      <div>
                                                        <span
                                                          dir="ltr"
                                                          className="f_ar_price"
                                                        >
                                                          {currency}{' '}
                                                        </span>
                                                        <span
                                                          dir="ltr"
                                                          className={`t-main-price ${
                                                            !!dPrice[
                                                              countryIndex
                                                            ] && 'line-through'
                                                          }`}
                                                        >
                                                          {price[
                                                            countryIndex
                                                          ].toLocaleString(
                                                            'en-US',
                                                            {
                                                              minimumFractionDigits: 2,
                                                              maximumFractionDigits: 2,
                                                            }
                                                          )}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <p>
                                                    {t('pdf.quantity')} {qty}
                                                  </p>
                                                </div>
                                                <div>
                                                  <Barcode
                                                    className="small-bar-code"
                                                    value={sku.toString()}
                                                    width={2}
                                                    height={40}
                                                  />
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                      {/*Addon END*/}
                                      {/*Close*/}
                                      <tr>
                                        <td className="padding-remove">
                                          <div className="grand-total grand-footer-price">
                                            <p className="footer-text">
                                              ({t('pdf.vatMsg')})
                                            </p>
                                            <div
                                              className="combine_price_mobiles"
                                              style={{
                                                justifyContent:
                                                  language === 'en'
                                                    ? ''
                                                    : 'flex-start',
                                              }}
                                            >
                                              <span>{t('pdf.total')}: </span>
                                              <span
                                                className="f_ar_price"
                                                style={{
                                                  order:
                                                    language === 'en'
                                                      ? ''
                                                      : '1',
                                                }}
                                              >
                                                {currency}{' '}
                                              </span>
                                              <span className="t-main-price">
                                                {(
                                                  totalPrice - discountedPrice
                                                ).toLocaleString('en-US', {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                })}
                                              </span>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="padding-remove">
                                          <div className="grand-total t-footer">
                                            <div>
                                              &copy; 2022 {t('pdf.copyRight')}
                                            </div>
                                            <div>*{t('pdf.message')}</div>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="clear"></div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </PdfWrapper>
  );
});
