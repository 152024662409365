import React, { useContext, useEffect, useState } from 'react';
import {
  countryList,
  defaultProductCategory,
  SECTIONS,
} from '../../../constants';
import { Wrapper } from './configurator.styles';

import ProductBlock from '../../../layouts/ProductBlock/ProductBlock';
import ColorBlock from '../../../layouts/ColorBlock/ColorBlock';
import Sizeblock from '../../../layouts/SizeBlock/SizeBlock';
import BedTabs from '../../../layouts/Tabs/BedTabs';
import { useDispatch, useSelector } from 'react-redux';
import { AppContext } from '../../../context';
import { setProductColor } from '../../../store/threekit';

const Configurator = ({ t }) => {
  const dispatch = useDispatch();

  const { selected } = useContext(AppContext);

  const {
    summary,
    products,
    category,
    collection,
    product,
    size,
    type,
    color,
    country,
  } = useSelector((state) => state.threekit);
  const isSofa = category === defaultProductCategory;
  function handleClick() {
    document.querySelector('.wrap').classList.toggle('col-lg-4');
    document.querySelector('.wrap').classList.toggle('shift_right');
    document.querySelector('.side-config').classList.toggle('overflow-hiddens');
    document.querySelector('.play-area').classList.toggle('col-lg-8');
    document.querySelector('.play-area').classList.toggle('config_right');
    document.querySelector('.click-arrow').classList.toggle('rotate-arrow');
  }
  const [colorList, setColorList] = useState([]);
  const productList = products[category]?.[collection]?.[type]?.[size] || {};
  useEffect(() => {
    if (!Object.keys(productList).length) return;
    const getColorList = (productObj) => {
      let allColors = [];
      const countryIndex = countryList.indexOf(country);
      const { en, ar, short, ...colorsObj } = productObj;
      Object.keys(colorsObj).forEach((color) => {
        const { price } = colorsObj[color];
        const countryPrice = price[countryIndex];
        if (!allColors.includes(color) && !!countryPrice)
          allColors = [...allColors, color];
      });
      return allColors;
    };

    if (category === 'Sofa' && !!selected) {
      // If a specific model is selected, then present colors associated to that product
      const productObj = productList?.[product] || {};
      const allColors = getColorList(productObj);

      setColorList(allColors.sort());
    } else if (category === 'Bed' && !!summary.length) {
      // get common colors for bedbase and headboard assemblies
      let allColors = [];
      const bedbaseShort = summary.find(({ type }) => type === 'Base')?.short;
      const headboardShort = summary.find((i) => i.type === 'Headboard')?.short;
      const commonObj = products[category]?.[collection] || {};

      const bedPartsObj = {
        ...(!!bedbaseShort && {
          Base: commonObj?.['Base']?.[size][bedbaseShort],
        }),
        ...(!!headboardShort && {
          Headboard: commonObj?.['Headboard']?.[size][headboardShort],
        }),
      };

      Object.values(bedPartsObj).forEach((productObj, i) => {
        if (!!productObj) {
          const newProductColors = getColorList(productObj);
          allColors = !i
            ? newProductColors // set first color array
            : allColors.filter((color) => newProductColors.includes(color));
        }
      });

      setColorList(allColors.sort());
    } else {
      // If no model is selected, then present only colors common to all products
      let allColors = [];
      Object.values(productList).forEach((productObj) => {
        const newProductColors = getColorList(productObj);
        allColors = [...new Set([...allColors, ...newProductColors])];
      });

      setColorList(allColors.sort());
    }
  }, [selected, product, size, productList, country, color, summary, dispatch]);

  useEffect(() => {
    // set new color on color list change
    const newColor = colorList.includes(color) ? color : colorList[0];
    if (!!newColor && newColor !== color) dispatch(setProductColor(newColor));
  }, [colorList]);
  //MOBILE
  function mobileClick() {
    document
      .querySelector('.overlap-hidden')
      .classList.toggle('configrator-mobile');
    document.querySelector('.pick').classList.toggle('d-none');
    document.querySelector('.return').classList.toggle('d-none');
    document.querySelector('.play-area').classList.toggle('d-none');
  }

  return (
    <Wrapper className="col-lg-4 col-12 wrap product_sidebar_padding">
      {/* TODO: remove this div? */}
      <div className="click-arrow" onClick={handleClick}>
        <span className="demo-arrow-cls back"></span>
      </div>

      <div className="overlap-hidden">
        <div className="side-config">
          {!isSofa && <Sizeblock t={t} />}
          <ColorBlock t={t} colorList={colorList} />
          {isSofa ? (
            <ProductBlock t={t} colorList={colorList} />
          ) : (
            <BedTabs t={t} colorList={colorList} />
          )}
        </div>
      </div>

      {/*CONFIGURATOR FOR MOBILE ONLY*/}
      <button
        className="custom-button resume-buttton mobile-toogle-button"
        onClick={mobileClick}
      >
        <span className="d-none return">
          Return to 3D view <i className="fa fa-angle-down"></i>
        </span>
        <span className="pick">
          Pick Colours & components <i className="fa fa-angle-up"></i>
        </span>
      </button>
      {/*CONFIGURATOR FOR MOBILE ONLY*/}
    </Wrapper>
  );
};

export default Configurator;
