import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './store';
import Wrapper from './app.styles';
import './i18next';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import { BrowserRouter } from 'react-router-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';
const options = {
  position: positions.TOP_RIGHT,
  timeout: 5000,
  transition: transitions.SCALE,
};

const msalInstance = new PublicClientApplication(msalConfig);

const LandMarkProject = () => {
  return (
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <Provider store={store}>
          <Wrapper>
            <AlertProvider template={AlertTemplate} {...options}>
              <App />
            </AlertProvider>
          </Wrapper>
        </Provider>
      </BrowserRouter>
    </MsalProvider>
  );
};

ReactDOM.render(<LandMarkProject />, document.getElementById('root'));
