import styled from 'styled-components';

export const Wrapper = styled.div`
  .form-controls {
    font-size: 16px;
    line-height: 24px;
    color: #212121;
    height: 48px;
    width: 100%;
    outline: none;
    border-radius: 0px;
    /* cursor: pointer; */
    cursor: ${({ disabled }) => (disabled ? 'wait' : 'default')};
    [disabled] {
      pointer-events: none;
      opacity: 0.7;
    }
  }

  // .header_dropdown.ant-select-open::after {
  //   content: '';
  //   position: absolute;
  //   width: 100%;
  //   height: 30px;
  //   background: #fff;
  //   z-index: -1;
  //   border-left: 1px solid #c8c9c6;
  //   border-right: 1px solid #c8c9c6;
  // }

  .form-controls .ant-select-selector {
    height: 100% !important;
    padding: 0 !important;
    border: 1px solid #c8c9c6 !important;
    outline: none !important;
    box-shadow: none !important;
    align-items: center;
  }

  .inner-logo img {
    margin-top: -12px;
    max-width: 35%;
    cursor: pointer;
  }

  .combine {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #212121;
  }
  .ReactFlagsSelect-module_flagsSelect__2pfa2 {
    margin: auto;
  }

  .storeid-outer,
  .storeid {
    background-color: transparent;
    border: none;
    font-size: 16px;
    font-weight: 400;
    color: #212121;
  }

  .top-header .col-3.padding-reduce {
    padding: 0;
  }
  .custom-button {
    max-width: 251px;
    height: 48px;
    background: #7665a0;
    box-shadow: 0px 1px 1px rgb(0 0 0 / 15%), 0px 2px 10px rgb(0 0 0 / 8%);
    border-radius: 0px;
    color: #fff;
    border: none;
    width: 100%;
    text-transform: uppercase;
  }

  .combine ul {
    padding: 0;
  }
  .combine .ReactFlagsSelect-module_filterBox__3m8EU input {
    margin: 0 !important;
    width: 100% !important;
    border: 1px solid #c8c9c6;
  }
`;
