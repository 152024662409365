import React, { useEffect } from 'react';
import Player from './Player';
import Configurator from './Configurator';
import Provider from '../../context';
import Header from '../Header';
import Footer from '../Footer';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { productApi, addonApi } from '../../constants';
import { setProducts, setAddons, setDesignId } from '../../store/threekit';
import { transformProducts, transformAddons } from '../../utils';

export default function ConfiguratorArea() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { products, designId } = useSelector((s) => s.threekit);

  // TODO: refactor into one promise all
  useEffect(() => {
    // check if products obj is empty. case for "edit configuration"
    if (!designId) dispatch(setDesignId());
    if (!Object.keys(products).length)
      (async () => {
        try {
          const productResult = await axios.get(productApi);
          const products = transformProducts(productResult?.data?.rows || []);
          dispatch(setProducts(products));
        } catch (err) {
          console.log('Err = ', err);
        }
      })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const addonResult = await axios.get(addonApi);
        const addons = transformAddons(addonResult?.data?.rows || []);
        dispatch(setAddons(addons));
      } catch (err) {
        console.log('Err = ', err);
      }
    })();
  }, []);

  return (
    <Provider>
      <Header t={t} />
      <div className="main-wrappr">
        <div className="row m-0">
          <Player t={t} />
          <Configurator t={t} />
        </div>
      </div>
      <Footer t={t} />
    </Provider>
  );
}
