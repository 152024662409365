import * as React from 'react';
import components from './IconSVGConfig';

const IconSVG = ({ name, customDimensions, ...props }) => {
  if (!name) return null;

  const Component = components[name.toString()];
  if (!Component) return null;

  const style = customDimensions ? null : {
    width: '1.5rem',
    height: 'auto'
  };

  return (
    <Component style={style} {...props} />
  );
};

IconSVG.defaultProps = {
  customDimensions: false
};

export default IconSVG;