import styled from 'styled-components';

export const Wrapper = styled.div`
  .footer-price span {
    font-size: 18px;
    line-height: 20px;
  }
  .footer-price > div {
    font-size: 18px;
    font-weight: 600;
  }
  
  .footer-button {
    text-transform: uppercase;
    background: #ffffff;
    border: 1px solid #7665a0;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 12px 35px;
    color: #7665a0;
    margin: 0 10px;
    text-align: center;
    text-decoration: none;
  }
  .footer-button:hover {
    background: #7665a0;
    box-shadow: 0px 1px 1px rgb(0 0 0 / 15%), 0px 2px 10px rgb(0 0 0 / 8%);
    color: #fff;
  }
  .footer-price .sale-price {
    color: #D21818;
    font-weight: 900;
    margin: 0 5px 0 0;
  }
  .disabled {
    cursor: not-allowed !important;
    background: #80808075;
  }
  .disabled:hover {
    color: #7665a0 !important;
    background: #80808075 !important;
  }
  span.without_sale_price {
    margin: 0 5px;
  }
  .footer_without_dprice {
    color: #212121;
  }

  @media (max-width: 992px) {
    
    .footer-buttons {
      justify-content: center;
    }
   .footer-button {
      padding: 12px 20px;
  }

`;
