import React from 'react';
import { Wrapper } from './FormattedPrice.styled';

const FormattedPrice = ({ price = 0, dPrice = 0 }) => {
  return (
    <Wrapper
      price={!!price}
      dPrice={!!dPrice}
      className="product-grid-price footer_without_dprice"
    >
      {!!price
        ? price.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})
        : dPrice.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
    </Wrapper>
  );
};

export default FormattedPrice;
