import styled from 'styled-components';

export const OptionWrapper = styled.div`
  padding: 4px 10px;
  cursor: pointer;
  margin: 4px 0;
  text-align: ${(p) => (p.language === 'en' ? 'left' : 'right')};
  color: #212121;
  background-color: ${(p) => (p.isSelected ? '#eaeaea' : '#fff')};
  &:hover {
    background-color: #eaeaea;
  }
`;

export const OptionItem = styled.div`
  padding: 0 10px;
  display: inline-block;
`;
