import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { countryList, sizesOrderedList } from '../../constants';
import { setPlayerReady, setProductSize } from '../../store/threekit';
import { setBedAttributes } from '../../utils/attributes';
import { SizeBlock } from './SizeBlock.styles';

export default function Sizeblock({ t }) {
  const dispatch = useDispatch();
  const {
    products,
    category,
    collection,
    size,
    type,
    color,
    summary,
    country,
  } = useSelector((s) => s.threekit);

  let bedModelSize = '';
  if (window.threekit?.configurator) {
    const [bedModel] = window.threekit.configurator.getItems().values();
    bedModelSize = bedModel?.configuration?.Bedbase?.size;
  }

  useEffect(() => {
    // update size on model size change (case for Undo click)
    if (!!bedModelSize && bedModelSize !== size)
      dispatch(setProductSize(bedModelSize));
  }, [bedModelSize]);

  const getSizes = (checkedType) => {
    const summaryProduct =
      summary.find((item) => item.type === checkedType) || {};
    const shortProduct = summaryProduct.short;
    const countryIndex = countryList.indexOf(country);

    const sizeObj = products[category]?.[collection]?.[checkedType] || {};
    const sizes = Object.keys(sizeObj).filter((size) => {
      // check if we have a price for this size

      const shortProductList = sizeObj[size] || {};
      if (!!shortProduct && !!color) {
        const productPriceList =
          shortProductList?.[shortProduct]?.[color]?.price || [];
        return !!productPriceList[countryIndex]; // return size when summary product has available price
      }
      // return all sizes when no color and size is selected (filtered by price)
      const findAnyProductPrice = Object.keys(shortProductList).find(
        (shortProduct) => {
          const productPriceList =
            shortProductList?.[shortProduct]?.[color]?.price || [];
          return !!productPriceList[countryIndex];
        }
      );
      return !!findAnyProductPrice;
    });
    return sizes;
  };
  const bedbaseSizes = getSizes('Base');
  const headboardSizes = getSizes('Headboard');

  const commonSizes = bedbaseSizes.filter((i) => headboardSizes.includes(i)); // get common (Bedbase/Headboard) sizes. If certain size is not present in Base - it should not appear in Headboard and vise versa

  const sizes = sizesOrderedList.filter((i) => commonSizes.includes(i)); // get presorted sizes from sizesOrderedList constant

  useEffect(() => {
    const updateModelSize = async () => {
      try {
        setBedAttributes({ newSize: size });
      } catch (err) {
        console.log(`err [updateModelSize] = `, err);
        dispatch(setPlayerReady(true));
      }
    };
    if (!!collection) updateModelSize();
  }, [collection]);

  const handleSizeChange = async (newSize) => {
    setBedAttributes({ newSize });
    dispatch(setProductSize(newSize));
  };

  return (
    <SizeBlock>
      <>
        <div className="size-area">
          <h5 className="all-title">{t('sidebar.size')}</h5>
          <div className="size-block">
            <ul className="list-unstyled">
              {sizes.map((currentSize, index) => {
                return (
                  <li
                    key={index.toString()}
                    className={
                      currentSize === size ? 'active_size' : 'unactive'
                    }
                    onClick={() => handleSizeChange(currentSize)}
                  >
                    {t(`common.sizes.${currentSize}`)}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </>
    </SizeBlock>
  );
}
