import React, { useEffect, useState } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { useTranslation } from 'react-i18next';
import { Wrapper } from './itemsummary.styles';
import Customerdetails from '../CustomerDetails';
import { setAddonsProduct } from '../../store/threekit';

import { useDispatch, useSelector } from 'react-redux';
import {
  countryList,
  currencyCoutryList,
  currencyList,
  defaultProductCategory,
} from '../../constants';
import { summaryToObj } from '../../utils';

//AddOn Component
const AddonsItems = (props) => {
  const [count, setCount] = useState(0);
  const [image, setImage] = useState(
    `../../assets/Homecentre_Watermark_Threekit.png`
  );
  const { t, product, handleClick, language, country, currentCollection } =
    props;
  const { addOnsSummary } = useSelector((s) => s.threekit);
  const { en, ar, price, dPrice, sku, img } = product;
  const productNames = { en, ar };
  const countryIndex = countryList.indexOf(country);
  const countryPrice = price[countryIndex];
  const countryDiscoutedPrice = dPrice[countryIndex];
  const currency = t(`common.currencies.${currencyCoutryList[country]}`);
  useEffect(() => {
    try {
      setImage(require(`../../assets/addons/${img}.jpeg`));
    } catch {
      setImage(require(`../../assets/Homecentre_Watermark_Threekit.png`));
    }
  }, [en, currentCollection, language]);

  //MOBILE
  function productClick() {
    document
      .querySelector('.overlap-hidden')
      .classList.remove('configrator-mobile');
    document.querySelector('.outer-table').classList.remove('d-none');
  }
  const qtyCount = addOnsSummary?.[sku]?.['qty'] || 0;

  return (
    <div className="col-sm-6 col-6 custom_grid_padding full_height">
      <div className="product-addon" onClick={productClick}>
        <div className="img">
          <img className="w-100" src={image} />
        </div>

        <div className="outer_title_or_price">
          <div className="title-addon-product">{productNames[language]}</div>

          <div className="align-items-center d-flex count-number justify-content-around ">
            <button
              onClick={() => {
                let countDic = !qtyCount ? 0 : qtyCount - 1;
                setCount(countDic);
                const setQauntity = 'substraction';
                if (!!qtyCount) {
                  handleClick(en, ar, sku, price, dPrice, setQauntity, img);
                }
              }}
            >
              -
            </button>

            <div>{qtyCount}</div>

            <button
              onClick={() => {
                let countInc = count + 1;
                setCount(countInc);
                const setQauntity = 'addition';

                handleClick(en, ar, sku, price, dPrice, setQauntity, img);
              }}
            >
              +
            </button>
          </div>
          <div className="footer-price addon_price">
            <span>{currency} </span>

            <div className="combine_price">
              <span
                className={`main-price item_price ${
                  !countryDiscoutedPrice && 'no-discounted-price'
                } `}
              >
                {countryPrice.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
              <span className="sale-price">
                {!!countryDiscoutedPrice &&
                  countryDiscoutedPrice.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function Itemsummary() {
  const dispatch = useDispatch();

  const {
    language,
    summary: summaryArr,
    country,
    size,
    addons,
    category,
    collection,
    color,
    fromSummary,
    addOnsSummary,
  } = useSelector((s) => s.threekit);

  const summary = summaryToObj(summaryArr);
  let prodArray;

  if (category === 'Bed') {
    prodArray = Object.values(
      addons[category]?.[collection]?.[size]?.[size] || {}
    );
  } else {
    prodArray = Object.values(
      addons[category]?.[collection]?.[size]?.[size] || {}
    );
  }

  //console.log('prodArray', addons[category][collection]);

  const hasSize = size !== defaultProductCategory; // sofa does not have size

  const handleClick = (en, ar, sku, price, dPrice, setQauntity, img) => {
    dispatch(
      setAddonsProduct({ en, ar, sku, price, dPrice, setQauntity, img })
    );
  };
  const { t } = useTranslation();
  const skuList = Object.keys(summary);
  const countryIndex = countryList.indexOf(country);
  const currency = t(`common.currencies.${currencyCoutryList[country]}`);
  const addonsList = Object.keys(addOnsSummary).length
    ? Object.keys(addOnsSummary)
    : [];
  //MOBILE
  function summeryClick() {
    document
      .querySelector('.overlap-hidden')
      .classList.toggle('configrator-mobile');
    document.querySelector('.pick').classList.toggle('d-none');
    document.querySelector('.return').classList.toggle('d-none');
    document.querySelector('.outer-table').classList.toggle('d-none');
  }
  return (
    <>
      <Header t={t} />
      <Wrapper className="al-wrapper">
        <div className="row m-0">
          <div
            className={
              fromSummary === 3
                ? `only_mobile_hide col-lg-8 col-12`
                : `col-lg-8 col-12`
            }
          >
            <div className="outer-table">
              <h3 className="summary-title">{t('summary.itemSummary')} </h3>
              <label className="modular_item_name">
                {t('summary.modularItems')}
              </label>

              <div className="shopping-cart">
                <div className="column-labels">
                  <label className="product-image">
                    {t('summary.modularItems')}
                  </label>
                  <label className="product-price">{t('summary.price')}</label>
                  <label className="product-quantity">{t('summary.qty')}</label>
                  <label className="product-line-price">
                    {t('summary.subtotal')}
                  </label>
                </div>

                <div className="autoscroll">
                  {skuList.map((sku, index) => {
                    const summaryItem = summary[sku];
                    const sizeText = t(`common.sizes.${summaryItem.size}`);
                    return (
                      <div className="product" key={index.toString()}>
                        <div className="product-image">
                          <div className="d-flex">
                            <div className="symbol symbol-90 flex-shrink-0">
                              <img
                                className="symbol-label"
                                //src={require(`../../../src/assets/products/${summaryItem.collection}/${summaryItem.en}.jpg`)}
                                // `../../../src/assets/products/${summaryItem.collection}/${summaryItem.en}/${summaryItem.color}.png
                                src={require(`../../../src/assets/products/${summaryItem.collection}/${summaryItem.en}_${summaryItem.color}.png`)}
                                alt={summaryItem.en}
                              />
                            </div>
                            <div className="d-flex flex-column product-name-details w-100 mx-3 mx-lg-3 mx-md-4">
                              <a className="product-style-comeon" href="#">
                                {summaryItem[language]}{' '}
                              </a>
                              {hasSize && (
                                <span className="size">{sizeText}</span>
                              )}
                              <span className="sku">
                                SKU : {summaryItem.sku}
                              </span>
                              {/*THIS CODE IS SHOW ON MOBILE BUT FUNCTIONALITY MUST ADDED */}
                              <div className="mobile-full-product-area mt-2">
                                <div className="footer-price">
                                  <div className="hdkHde item_price product-style-comeon">
                                    <div className="inner-label">Price:</div>
                                    <div className="combine_price_mobile">
                                      <span
                                        className={`main-price item_price ${
                                          !summaryItem.dPrice[countryIndex] &&
                                          'no-discounted-price'
                                        }`}
                                      >
                                        {summaryItem.price[
                                          countryIndex
                                        ]?.toLocaleString('en-US', {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })}
                                      </span>
                                      <span className="sale-price">
                                        {summaryItem.dPrice[countryIndex] &&
                                          summaryItem.dPrice[
                                            countryIndex
                                          ].toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })}
                                      </span>
                                      <span className="ar_margin">
                                        {currency}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div className="product-quantity product-style-comeon">
                                  <div className="inner-label">Qty:</div>
                                  {summaryItem.qty}
                                </div>

                                <div className="product-line-price product-style-comeon">
                                  <div className="inner-label">Subtotal:</div>
                                  <div className="combine_price_mobile">
                                    <span className="ar_margin">
                                      {(!summaryItem.dPrice[countryIndex]
                                        ? summaryItem.price[countryIndex] *
                                          summaryItem.qty
                                        : summaryItem.dPrice[countryIndex] *
                                          summaryItem.qty
                                      ).toLocaleString('en-US', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                    </span>
                                    <span className="ar_margin">
                                      {currency}{' '}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              {/*THIS CODE IS SHOW ON MOBILE BUT FUNCTIONALITY MUST ADDED */}
                            </div>
                          </div>
                        </div>
                        {/*THIS CODE IS HIDDEN ON MOBILE BUT FUNCTIONALITY MUST ADDED */}
                        <div className="product-price product-style-comeon mobile-hiddden">
                          <div className="footer-price">
                            <div className="hdkHde item_price">
                              {currency}
                              <div className="combine_price">
                                <span
                                  className={`main-price item_price ${
                                    !summaryItem.dPrice[countryIndex] &&
                                    'no-discounted-price'
                                  }`}
                                >
                                  {summaryItem.price[
                                    countryIndex
                                  ]?.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </span>
                                <span className="sale-price">
                                  {summaryItem.dPrice[countryIndex] &&
                                    summaryItem.dPrice[
                                      countryIndex
                                    ].toLocaleString('en-US', {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="product-quantity product-style-comeon mobile-hiddden">
                          {summaryItem.qty}
                        </div>
                        <div className="product-line-price product-style-comeon mobile-hiddden">
                          <span>{currency} </span>
                          <span className="ar_price">
                            {(!summaryItem.dPrice[countryIndex]
                              ? summaryItem.price[countryIndex] *
                                summaryItem.qty
                              : summaryItem.dPrice[countryIndex] *
                                summaryItem.qty
                            ).toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </span>
                        </div>
                        {/*THIS CODE IS HIDDEN ON MOBILE BUT FUNCTIONALITY MUST ADDED */}
                      </div>
                    );
                  })}

                  {/** Addons products Start...*/}
                  {addonsList.length
                    ? addonsList.map((sku, i) => {
                        const addonsSummaryItem = addOnsSummary[sku];
                        return (
                          <div key={i.toString()}>
                            {!i ? (
                              <div className="column-labels addon-label">
                                <label>{t('summary.addons')} </label>
                              </div>
                            ) : (
                              ''
                            )}
                            <div className="product">
                              <div className="product-image">
                                <div className="d-flex">
                                  <div className="symbol symbol-90 flex-shrink-0">
                                    <img
                                      className="symbol-label"
                                      src={require(`../../../src/assets/addons/${addonsSummaryItem.img}.jpeg`)}
                                    />
                                  </div>
                                  <div className="d-flex flex-column product-name-details w-100 mx-3 mx-lg-3 mx-md-4">
                                    <a
                                      className="product-style-comeon"
                                      href="#"
                                    >
                                      {addonsSummaryItem[language]}
                                    </a>
                                    {/* {hasSize && (
                                      <span className="size">
                                        {addonsSummaryItem.size}
                                      </span>
                                    )} */}
                                    <span className="sku">SKU : {sku}</span>
                                    {/*THIS CODE IS SHOW ON MOBILE DESKTOP HIDDEN BUT FUNCTIONALITY MUST ADDED */}
                                    <div className="mobile-full-product-area mt-2">
                                      <div className="footer-price">
                                        <div className="hdkHde item_price product-style-comeon">
                                          <div className="inner-label">
                                            Price:
                                          </div>
                                          <div className="combine_price_mobile">
                                            <span
                                              className={`main-price item_price ${
                                                !addonsSummaryItem.dPrice[
                                                  countryIndex
                                                ] && 'no-discounted-price'
                                              } `}
                                            >
                                              {addonsSummaryItem.price[
                                                countryIndex
                                              ].toLocaleString('en-US', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              })}
                                            </span>
                                            <span className="sale-price ar_margin">
                                              {!!addonsSummaryItem.dPrice[
                                                countryIndex
                                              ]
                                                ? addonsSummaryItem.dPrice[
                                                    countryIndex
                                                  ].toLocaleString('en-US', {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                  })
                                                : ''}
                                            </span>
                                            <span className="ar_margin">
                                              {currency}
                                            </span>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="product-quantity product-style-comeon">
                                        <div className="inner-label">Qty:</div>
                                        {addonsSummaryItem.qty}
                                      </div>

                                      <div className="product-line-price product-style-comeon">
                                        <div className="inner-label">
                                          Subtotal:
                                        </div>
                                        <div className="combine_price_mobile">
                                          <span className="ar_margin">
                                            {!addonsSummaryItem.dPrice[
                                              countryIndex
                                            ]
                                              ? (
                                                  addonsSummaryItem.price[
                                                    countryIndex
                                                  ] * addonsSummaryItem.qty
                                                ).toLocaleString('en-US', {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                })
                                              : (
                                                  addonsSummaryItem.dPrice[
                                                    countryIndex
                                                  ] * addonsSummaryItem.qty
                                                ).toLocaleString('en-US', {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                })}
                                          </span>
                                          <span className="ar_margin">
                                            {currency}{' '}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    {/*THIS CODE IS SHOW ON MOBILE DESKTOP HIDDEN BUT FUNCTIONALITY MUST ADDED */}
                                  </div>
                                </div>
                              </div>
                              {/*THIS CODE IS HIDDEN ON MOBILE DESKTOP SHOW BUT FUNCTIONALITY MUST ADDED */}
                              <div className="product-price product-style-comeon mobile-hiddden">
                                <div className="footer-price">
                                  <div className="hdkHde item_price">
                                    {currency}
                                    <div className="combine_price">
                                      <span
                                        className={`main-price item_price ${
                                          !addonsSummaryItem.dPrice[
                                            countryIndex
                                          ] && 'no-discounted-price'
                                        } `}
                                      >
                                        {addonsSummaryItem.price[
                                          countryIndex
                                        ].toLocaleString('en-US', {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })}
                                      </span>

                                      <span className="sale-price">
                                        {!!addonsSummaryItem.dPrice[
                                          countryIndex
                                        ]
                                          ? addonsSummaryItem.dPrice[
                                              countryIndex
                                            ].toLocaleString('en-US', {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            })
                                          : ''}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="product-quantity product-style-comeon mobile-hiddden">
                                {addonsSummaryItem.qty}
                              </div>
                              <div className="product-line-price product-style-comeon mobile-hiddden">
                                <span>{currency} </span>
                                <span className="ar_price">
                                  {!addonsSummaryItem.dPrice[countryIndex]
                                    ? (
                                        addonsSummaryItem.price[countryIndex] *
                                        addonsSummaryItem.qty
                                      ).toLocaleString('en-US', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })
                                    : (
                                        addonsSummaryItem.dPrice[countryIndex] *
                                        addonsSummaryItem.qty
                                      ).toLocaleString('en-US', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                </span>
                              </div>
                              {/*THIS CODE IS HIDDEN ON MOBILE DESKTOP SHOW BUT FUNCTIONALITY MUST ADDED */}
                            </div>
                          </div>
                        );
                      })
                    : ''}
                  {/** Addons products END...*/}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-12">
            <div
              className={
                fromSummary === 3
                  ? `overlap-hidden customer_detail_mobile height-100`
                  : `overlap-hidden`
              }
            >
              <div className="side-config">
                {fromSummary === 2 ? (
                  <div className="add-on-panel">
                    <h5 className="all-title">{t('summary.addons')}</h5>
                    <div className="row">
                      {prodArray.map((product, index) => {
                        const { price } = product;
                        const countryIndex = countryList.indexOf(country);
                        const countryPrice = price[countryIndex];
                        if (!!countryPrice) {
                          return (
                            <AddonsItems
                              t={t}
                              country={country}
                              color={color}
                              product={product}
                              index={index}
                              key={index.toString()}
                              language={language}
                              handleClick={handleClick}
                              currentCollection={collection}
                            />
                          );
                        }
                      })}
                    </div>
                  </div>
                ) : (
                  <div className="customer-form-panel">
                    <Customerdetails t={t} />
                  </div>
                )}
              </div>
            </div>
            {/*TOGGLE BOTTOM BUTTONS FOR MOBILE ONLY START*/}
            <button
              className={
                fromSummary == 3
                  ? `d-none`
                  : `custom-button resume-buttton mobile-toogle-button`
              }
              onClick={summeryClick}
            >
              <span className="d-none return">
                Return Back To Summary <i className="fa fa-angle-down"></i>
              </span>
              <span className="pick">
                Pick Add-Ons <i className="fa fa-angle-up"></i>
              </span>
            </button>
            {/*TOGGLE BOTTOM BUTTONS FOR MOBILE ONLY END*/}
          </div>
        </div>
      </Wrapper>

      <Footer t={t} />
    </>
  );
}
