import styled from 'styled-components';

export const Wrapper = styled.span`
  text-decoration: ${(props) =>
    props.price && props.dPrice ? 'line-through' : 'none'};
  color: ${(props) => {
    if (props.price && props.dPrice) {
      // price with discount
      return '#afb0b1';
    } else if (props.price && !props.dPrice) {
      // price with no discount
      return '#afb0b1'; // '#000';
    } else {
      return '#d21818'; // discount
    }
  }};
  font-weight: 900;
`;
