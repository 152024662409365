// FLAGS

import { Ae, Kw, Bh, Om, Sa, Eg, Qa } from 'react-flags-select';

import ArrowDown from '../../assets/svg/arrowDown';

const Components = {
  // flags
  AE: Ae,
  KW: Kw,
  BH: Bh,
  OM: Om,
  DM: Sa,
  JE: Sa,
  RY: Sa,
  EG: Eg,
  QA: Qa,
  AA: Sa,

  // common
  arrowDown: ArrowDown,
};

export default Components;
