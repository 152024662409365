import React, { useContext, useState, useEffect } from 'react';
import { Wrapper } from './player.styles';
import media from '../../../assets';
import { Menu, Dropdown, Tooltip } from 'antd';
import { attributeIds, PRODUCT_COLOR_IDS } from '../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  removeProduct,
  removeProductByType,
  resetSummary,
  setUndoConfigArray,
  setDesignId,
  setPlayerReady,
  setProduct,
  setShowDimensions,
  setAccessories,
  setProductColor,
  setModelOpen,
} from '../../../store/threekit';
import { AppContext } from '../../../context';
import { Spin } from 'antd';
import { Modal, Button } from 'antd';
import { setConfigOnModel } from '../../../utils';
import { setBedAttributes } from '../../../utils/attributes';
import { getColorAssetId } from '../../../layouts/ColorBlock/ColorBlock';

export const findDiff = (items1, items2) => {
  const fullItems = items1.concat(items2);
  const intersaction = fullItems.filter(
    (item) => fullItems.filter((i) => i === item).length === 1
  );
  return intersaction[0];
};

export const getRemovedItem = (oldItems, items) => {
  if (oldItems.length <= items.length) {
    return null;
  }

  return findDiff(oldItems, items);
};

export const getAddedItem = (oldItems, items) => {
  if (oldItems.length >= items.length) {
    return null;
  }

  return findDiff(oldItems, items);
};

const Player = ({ t }) => {
  const dispatch = useDispatch();
  const [hasOpenModel, setHasOpenModel] = useState(false);
  const { selected, handleGetItem, handleDeleteItems, handleAddItem } =
    useContext(AppContext);
  const {
    category,
    collection,
    type,
    color,
    undoConfigArray,
    designId,
    isPlayerReady,
    summary,
    hasAccessories,
    isModelOpen,
    showDimensions,
  } = useSelector((s) => s.threekit);
  const currentConfigArray = undoConfigArray;
  const hasBaseAndHeadboard =
    !!summary.find(({ type }) => type === 'Headboard') &&
    !!summary.find(({ type }) => type === 'Base'); // check if a product has base AND headboard.

  useEffect(() => {
    const handleOpenState = async () => {
      // Find English name by Sofa selected model or Bed type
      let en;
      if (category === 'Sofa') {
        const selectedModel = await handleGetItem(selected);
        en = summary.find(({ sku }) => sku === selectedModel?.sku)?.en;
      } else {
        en = summary.find(({ type }) => type === 'Base')?.en;
      }

      setHasOpenModel(!!attributeIds[`${en}_Open`] ? en : false);
    };
    handleOpenState();
  }, [selected, summary, category]);

  useEffect(() => {
    // /* accessories are available only for 1) Bed Base => 2) NOT opened 3) with Headboard*/
    if (isModelOpen || !hasBaseAndHeadboard) dispatch(setAccessories(false));
  }, [hasBaseAndHeadboard, isModelOpen, hasAccessories]);

  const handleUndo = async () => {
    dispatch(setPlayerReady(false));
    if (!currentConfigArray.length) return;
    const newConfigArray = currentConfigArray.slice(0) || [];
    newConfigArray.pop();
    const configId = newConfigArray[newConfigArray.length - 1];

    dispatch(setUndoConfigArray(newConfigArray));

    const oldItems = Array.from(window.threekit.configurator.getItems().keys());
    const response = await window.threekit.configurator.reset({configId, frameScene: false});
    if (response?.metadata?.color) {
      dispatch(setProductColor(response.metadata.color));
    }
    const newItems = Array.from(window.threekit.configurator.getItems().keys());

    const removedItemId = getRemovedItem(oldItems, newItems);
    if (removedItemId) {
      dispatch(removeProduct(removedItemId));
    }
    dispatch(setPlayerReady(true));
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    const configurator = window.threekit.configurator;
    if (!configurator) {
      return;
    }

    configurator.setOpenState(isModelOpen);
    configurator.updateMeasureTargets();
  }, [isModelOpen]);

  const onDrop = async (e) => {
    if (!isPlayerReady) return;

    try {
      const {
        en,
        ar,
        short,
        sku,
        price,
        dPrice,
        category,
        size,
        designId,
        collection,
        currentProductColor,
        type,
        colorList,
      } = JSON.parse(e.dataTransfer.getData('data'));

      dispatch(setPlayerReady(false));
      let modelId;
      const newProductObj = {
        en,
        ar,
        short,
        sku,
        price,
        dPrice,
        category,
        size,
        designId,
        collection,
        color: currentProductColor,
        qty: 1,
        type,
        key: en,
        productType: type,
      };
      if (category === 'Sofa') {
        const config = {
          Color: {
            assetId: getColorAssetId(collection, currentProductColor),
          },
        };
        const itemObj = {
          ...newProductObj,
          type: collection,
          configuration: config,
        };
        modelId = await handleAddItem(itemObj);
        await setConfigOnModel(modelId, config);

        window.threekit.configurator.frameSceneAroundLoneItem();
      } else {
        const [firstPlayerModel] = await window.threekit.configurator
          .getItems()
          .values();
        modelId = firstPlayerModel.id;

        const key = attributeIds[type]; // Bedbase or Headboard

        const typeConfig = {
          [key]: { ...newProductObj, assetId: getColorAssetId(key) },
        };
        await setConfigOnModel(modelId, typeConfig);
        const colorConfig = {
          Color: {
            assetId: PRODUCT_COLOR_IDS[collection]?.[currentProductColor],
          },
        };
        await setConfigOnModel(modelId, colorConfig);

        window.threekit.configurator.configureItems(modelId, {
          configuration: { ...typeConfig, ...colorConfig },
          type: 'Bed Collection',
          key: en,
          qty: 1,
          productType: type,
          color: currentProductColor,
        });

        window.threekit.configurator.frameSceneAroundItems();

        if (summary.find((i) => i.type === type))
          dispatch(removeProductByType(type)); // remove summary old type (bedbase or headboard)
      }
      // TODO: refactor undo to a single function
      const configId = await window.threekit.configurator.saveConfiguration({
        designId,
        collection,
        type,
        color,
      });
      const newPlayerConfigs = [...undoConfigArray, configId];
      dispatch(setUndoConfigArray(newPlayerConfigs));
      // saveUndoConfiguration();
      if (!colorList.includes(color)) {
        dispatch(setProductColor(currentProductColor));
      }
      dispatch(setProduct({ ...newProductObj, modelId }));

      dispatch(setPlayerReady(true));

      // const playerModels = await window.threekit.configurator.getItems();
      // if (category !== defaultProductCategory && !!playerModels.size) {
      //   // remove previous bed before adding new bed. We can have only one bed at a time
      //   const [firstPlayerModel] = playerModels.values(); // We should have only one bed in the player. So we delete and add to open/close
      //   const selectedProduct = handleGetItem(firstPlayerModel.id);
      //   if (!selectedProduct) return;
      //   handleDeleteItems([selectedProduct.id]);
      // }
      // const modelId = await handleAddItem({ collection, en, sku });
      // dispatch(setProduct({ en, ar, short, sku, price, dPrice, modelId }));
      // // await handleAddItem(collection, en);
      // const configId = await window.threekit.configurator.saveConfiguration({
      //   designId,
      //   collection,
      //   type,
      // }); // save design for handle back. we dont have hasDesign property
      // const newPlayerConfigs = [...undoConfigArray, configId];
      // dispatch(setUndoConfigArray(newPlayerConfigs));
    } catch (err) {
      console.log('err Ondrop = ', err);
      dispatch(setPlayerReady(true));
    }
  };

  const handleDelete = async () => {
    if (category === 'Sofa') {
      if (!selected) return;
      handleDeleteItems([selected]);
      dispatch(removeProduct(selected));
      const configId = await window.threekit.configurator.saveConfiguration({
        designId,
        collection,
        type,
        color,
      });
      const newPlayerConfigs = [...undoConfigArray, configId];
      dispatch(setUndoConfigArray(newPlayerConfigs));
    } else {
      dispatch(removeProductByType(type));
      setBedAttributes();
    }
  };

  const handleOpen = async () => {
    dispatch(setPlayerReady(false));
    try {
      const openPostfix = isModelOpen ? null : '_Open';
      const key = isModelOpen ? hasOpenModel : `${hasOpenModel}${openPostfix}`;
      if (category === 'Sofa') {
        const configuration = {
          Options: { assetId: attributeIds[key] },
        };
        await setConfigOnModel(selected, configuration);
        await window.threekit.configurator.configureItems(selected, {
          configuration,
        });
      } else {
        const [firstPlayerModel] = window.threekit.configurator
          .getItems()
          .values();
        const itemId = firstPlayerModel.id;

        const typeConfig = {
          Bedbase: { assetId: attributeIds[key] },
        };
        await setConfigOnModel(itemId, typeConfig);
      }
      dispatch(setModelOpen(!isModelOpen));
      // save configuration
      const configId = await window.threekit.configurator.saveConfiguration({
        designId,
        collection,
        type,
        color,
      });
      const newPlayerConfigs = [
        ...undoConfigArray,
        configId,
        // [type]: [...playerConfigs[type], configId],
      ];
      dispatch(setUndoConfigArray(newPlayerConfigs));
      //Open-Close Button Title Change
    } catch (err) {
      console.log('err handle Open = ', err);
      dispatch(setPlayerReady(true));
    }
    dispatch(setPlayerReady(true));
    await window.threekit.configurator.updateMeasureTargets();
  };
  //Apply Lighting on Bed/Sofa
  const sceneLight = async (e) => {
    dispatch(setPlayerReady(false));
    const configurator = await window.threekit.api.getConfigurator();

    configurator.setConfiguration({
      Temperature: e,
    });
    dispatch(setPlayerReady(true));
  };

  const toggleDimensions = () => dispatch(setShowDimensions(!showDimensions));

  useEffect(() => {
    if (!window.threekit?.api || !isPlayerReady) {
      return;
    }

    window.threekit.configurator.showMeasurement(showDimensions, category);
  }, [window.threekit, showDimensions, category, isPlayerReady]);

  //Add Bed Accessories
  const addAccessories = async (e) => {
    dispatch(setPlayerReady(false));
    dispatch(setAccessories(!hasAccessories));
    await setBedAttributes();
    dispatch(setPlayerReady(true));
  };

  //Camera Zoom-IN/Zoom-OUT
  const zoom = (val) => window.api.camera.zoom(val);
  const zoomInCamera = () => zoom(1);
  const zoomOutCamera = () => zoom(-1);

  const menu = (
    <Menu className="playerdropdown">
      <Menu.Item key="0" onClick={() => sceneLight('Cool')}>
        <span className="color-code" style={{ backgroundColor: '#D1E1EA' }}>
          &nbsp;
        </span>{' '}
        {`${t('player.cool')}`}
      </Menu.Item>
      <Menu.Item key="1" onClick={() => sceneLight('Natural')}>
        <span className="color-code" style={{ backgroundColor: '#E6E0D9' }}>
          &nbsp;
        </span>
        {`${t('player.natural')}`}
      </Menu.Item>
      <Menu.Item key="3" onClick={() => sceneLight('Warm')}>
        <span className="color-code" style={{ backgroundColor: '#F8EFD2' }}>
          &nbsp;
        </span>
        {`${t('player.warm')}`}
      </Menu.Item>
    </Menu>
  );

  //Start Over Button Start
  const [isModalStartOver, setModalStartOver] = useState(false);
  const handleCancelStar = () => {
    setModalStartOver(false);
  };
  const startOver = async () => {
    setModalStartOver(true);
  };

  async function startOverRedirection(key) {
    if (key === true) {
      if (category === 'Bed') {
        const [firstPlayerModel] = window.threekit.configurator
          .getItems()
          .values();
        const itemId = firstPlayerModel.id;
        const typeConfig = {
          Bedbase: { assetId: attributeIds[`Bedbase_None`] },
          Headboard: { assetId: attributeIds[`Headboard_None`] },
        };
        setConfigOnModel(itemId, typeConfig); // set none atributes on bed reset
      } else {
        await window.threekit.configurator.reset();
      }
      window.threekit.editConfigId = '';
      dispatch(setUndoConfigArray([]));
      dispatch(resetSummary());
      dispatch(setDesignId());
      setModalStartOver(false);
    } else {
      setModalStartOver(false);
    }
  }
  //Start Over Button End

  function rotateCW() {
    window.threekit.configurator.rotateItems([selected], -90);
  }

  function rotateCCW() {
    window.threekit.configurator.rotateItems([selected], 90);
  }

  return (
    <>
      <Wrapper
        className="col-lg-8 col-12 play-area position-relative"
        onDrop={onDrop}
        onDragOver={onDragOver}
      >
        <div className="overlay-images">
          <Tooltip placement="bottom" title={`${t('player.lighting')}`}>
            <Dropdown overlay={menu} trigger={['click']} arrow>
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <img src={media.color} className="light_color" />
              </a>
            </Dropdown>
          </Tooltip>
          {!!undoConfigArray.length && (
            <Tooltip placement="bottom" title={`${t('player.undo')}`}>
              <img src={media.undo} onClick={handleUndo} />
            </Tooltip>
          )}
          <Tooltip
            placement="bottom"
            title={`${t('player.refresh')}`}
            onClick={() => startOver()}
          >
            <img src={media.refresh} />
          </Tooltip>
          {/* open / close for bed */}

          {!!hasOpenModel && (
            <Tooltip
              placement="bottom"
              title={
                isModelOpen ? `${t('player.close')}` : `${t('player.open')}`
              }
              onClick={handleOpen}
            >
              <img src={media.open} />
            </Tooltip>
          )}
          {/* accessories are available only for 1 Bed Base => 2 NOT opened 3 with Headboard*/}
          {!!summary.find(({ type }) => type === 'Headboard') &&
            !!summary.find(({ type }) => type === 'Base') &&
            !isModelOpen && (
              <Tooltip
                placement="bottom"
                title={
                  hasAccessories
                    ? `${t('player.minus_accessories')}`
                    : `${t('player.plus_accessories')}`
                }
                onClick={addAccessories}
              >
                <img
                  src={
                    hasAccessories
                      ? media.minus_accessories
                      : media.plus_accessories
                  }
                />
              </Tooltip>
            )}

          <Tooltip
            placement="bottom"
            title={`${t('player.dimension')}`}
            onClick={toggleDimensions}
          >
            <img src={media.dimension} />
          </Tooltip>
        </div>
        <div className="player_outer">
          <div id="player_loader">
            {!isPlayerReady && <Spin tip="Loading..."></Spin>}
          </div>
          <div id="player-root" />

          <div className="plyer-bottom-icons" id="icons">
            <Tooltip
              placement="top"
              title={`${t('player.zoom-in')}`}
              onClick={() => zoomInCamera('Cool')}
            >
              <img src={media.player_zoom} />
            </Tooltip>

            <Tooltip
              placement="top"
              title={`${t('player.zoom-out')}`}
              onClick={() => zoomOutCamera('Cool')}
            >
              <img src={media.player_minus} />
            </Tooltip>
            {category === 'Sofa' && !!selected && (
              <>
                <Tooltip
                  placement="top"
                  title={`${t('player.rotate')}`}
                  onClick={() => rotateCCW()}
                >
                  <img src={media.rotate_ccw} />
                </Tooltip>
                <Tooltip
                  placement="top"
                  title={`${t('player.rotate')}`}
                  onClick={() => rotateCW()}
                >
                  <img src={media.rotate_cw} />
                </Tooltip>
              </>
            )}
            {category === 'Bed' || (category === 'Sofa' && !!selected) ? ( // show always for bed, show when sofa is selected
              <Tooltip placement="top" title={`${t('player.delete')}`}>
                <img
                  src={media.player_close}
                  onClick={() => {
                    handleDelete();
                  }}
                />
              </Tooltip>
            ) : (
              ''
            )}
          </div>
        </div>

        {/*Start Over Modal Start*/}
        <Modal
          visible={isModalStartOver}
          onCancel={handleCancelStar}
          centered
          footer={null}
        >
          <div className="save-later-modal">
            <h3>{t('modal.thisWillReset')}</h3>
            <p className="title">{t('modal.doYouReally')}</p>
            <Button onClick={() => startOverRedirection(false)}>
              {t('modal.no')}
            </Button>
            <Button onClick={() => startOverRedirection(true)}>
              {t('modal.yes')}
            </Button>
          </div>
        </Modal>
        {/*Start Over Modal End*/}
      </Wrapper>
    </>
  );
};

export default Player;
