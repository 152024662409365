import styled from 'styled-components';

export const Wrapper = styled.div`
  .f-700 {
    font-weight: 700;
  }
  .validation-errors {
    color: red;
  }
  .cust-det {
    font-size: 14px;
    color: #212121;
    margin-bottom: 6px;
  }

  .contact_input {
    display: flex;
    border: 1px solid #c8c9c6;
  }

  .counter_code {
    height: 48px;
    display: flex;
    align-items: center;
    margin: 0 10px;
  }

  .contact_input .form-control-input {
    border: none !important;
    padding: 0px 5px !important;
    // max-width: 110px;
  }


  .send-button .anticon.anticon-check-circle {
    font-size: 24px;
  }

  .send-button .anticon.anticon-check-circle svg {
    filter: grayscale(1);
  }

  .from-inputs .form-control-input {
    width: 100%;
    border: 1px solid #c8c9c6;
    box-sizing: border-box;
    border-radius: 2px;
    height: 48px;
    font-size: 15px;
    padding: 0px 16px;
    outline: none;
    box-shadow: none;
    font-weight: 400;
  }

  .from-inputs .form-group {
    margin: 0 0 15px 0;
  }
  button.form-button {
    background: #7665a0;
    box-shadow: 0px 1px 1px rgb(0 0 0 / 15%), 0px 2px 10px rgb(0 0 0 / 8%);
    color: #fff;
    border: 1px solid #7665a0;
    box-sizing: border-box;
    border-radius: 2px;
    width: 100%;
    height: 44px;
    margin: 10px;
    max-width: 170px;
  }

  button.form-button:hover {
    border: 1px solid #7665a0;
    background-color: transparent;
    color: #7665a0;
  }
`;
