import styled from 'styled-components';

export const Wrapper = styled.div`
  #tabs-size .ant-tabs-tab {
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    color: #212121 !important;
    border: 1px solid rgba(0, 0, 0, 0.125);
    width: 100%;
    margin: 0;
    text-align: center;
    border-bottom: none;
    padding: 8px 40px;
    letter-spacing: 0.2px;
  }

  #tabs-size .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #212121;
  }

  #tabs-size .main-product-titles {
    display: none;
  }

  #tabs-size .ant-tabs-tab.ant-tabs-tab-active {
    background-color: #ffffff;
  }

  .ant-tabs-ink-bar-animated {
    display: none !important;
  }

  #tabs-size .ant-tabs-content.ant-tabs-content-animated.ant-tabs-top-content {
    margin-top: 20px;
  }

  #tabs-size .ant-tabs-tabpane.ant-tabs-tabpane-active {
    margin: 0% !important;
    visibility: visible !important;
  }

  #tabs-size .ant-tabs-tabpane.ant-tabs-tabpane-inactive.bed_tabs {
    opacity: 1;
    visibility: visible !important;
    height: 100% !important;
  }
`;
