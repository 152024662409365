import React, { useState, useRef } from 'react';
import {
  MenuWrapper,
  ArrowIcon,
  FlagIcon,
  SelectedValue,
} from './DropDown.styled';
import DropDownOption from './DropDownOption';
import IconSVG from '../Icon/IconSVG';
import { countryList } from '../../constants';

const DropDown = ({ t, onChange, country }) => {
  const [isVisible, setIsVisible] = useState(false);
  const wrapperRef = useRef(null);

  const handleChangeIsVisible = () => {
    setIsVisible(!isVisible);
  };
  const handleChangeDropdown = (value) => {
    setIsVisible(false);
    onChange(value);
  };
  const onEscClick = (e) => {
    if (e.key === 'Escape') {
      setIsVisible(false);
    }
  };

  const onCloseHandler = (ref) => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', onEscClick);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', onEscClick);
    };
  };

  onCloseHandler(wrapperRef);
  return (
    <div ref={wrapperRef}>
      <SelectedValue onClick={() => handleChangeIsVisible()}>
        <FlagIcon name={country} customDimensions />
        <ArrowIcon name="arrowDown" customDimensions />
      </SelectedValue>
      {isVisible && (
        <MenuWrapper>
          {countryList.map((item) => {
            return (
              <DropDownOption
                handleChangeDropdown={handleChangeDropdown}
                value={item}
                key={item}
                country={country}
                t={t}
                setIsVisible={setIsVisible}
              >
                <IconSVG name={item} />
                {item}
              </DropDownOption>
            );
          })}
        </MenuWrapper>
      )}
    </div>
  );
};

export default DropDown;
