import React, { useState } from 'react';
import ProductCard from '../../Components/ProductCard/ProductCard';
import { Wrapper, BlockTitle } from './ProductBlock.styled';
import { useSelector } from 'react-redux';

const ProductBlock = ({ t, colorList }) => {
  const [inputValue, setInputValue] = useState(0);
  const { products, category, collection, size, type, color } = useSelector(
    (s) => s.threekit
  );
  const handleChange = (e) => {
    const offerIndex = +e.target.value;
    setInputValue(offerIndex);
  };

  const prodArray = Object.values(
    products[category]?.[collection]?.[type]?.[size] || {}
  ).filter(({ en, ar, short, ...colors }) => !!colors[color] || !color); // filter by selected color or all colors

  return (
    <>
      <BlockTitle className="main-product-titles">
        {t(`common.component`)}
      </BlockTitle>
      <div className="product-grid-area">
        <Wrapper
          className="childrenContainer row"
          name="products"
          onChange={handleChange}
          value={inputValue}
        >
          {prodArray.map((product, index) => {
            return (
              <ProductCard
                t={t}
                colorList={colorList}
                product={product}
                key={index}
                value={index}
                color={color}
              />
            );
          })}
        </Wrapper>
      </div>
    </>
  );
};

export default ProductBlock;
