import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import threekit from './threekit';

export default configureStore({
  reducer: Object.assign(
    {},
    {
      threekit,
    }
    // reducers
  ),
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }).concat(logger),
});
