import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  attributeIds,
  countryList,
  currencyCoutryList,
  currencyList,
  PRODUCT_COLOR_IDS,
} from '../../constants';
import { AppContext } from '../../context';
import {
  setUndoConfigArray,
  setPlayerReady,
  setProduct,
  setProductColor,
  resetSummary,
} from '../../store/threekit';
import FormattedPrice from '../common/FormattedPrice';
import {
  Wrapper,
  ProductTitle,
  ProductPrice,
  HiddenInput,
} from './ProductCard.styled';

import { setConfigOnModel } from '../../utils';
import { getColorAssetId } from '../../layouts/ColorBlock/ColorBlock';

const ProductCard = ({
  t,
  colorList,
  product: productItem,
  value,
  name,
  color,
}) => {
  const dispatch = useDispatch();
  const {
    isPlayerReady,
    collection,
    category,
    language,
    country,
    summary,
    designId,
    type,
    undoConfigArray,
    size,
    hasAccessories,
    addOnsSummary,
  } = useSelector((state) => state.threekit);

  const { selected, handleAddItem, handleGetItem } = useContext(AppContext);

  const [isChecked, setChecked] = useState(false);
  const [image, setImage] = useState(
    `../../assets/Homecentre_Watermark_Threekit.png`
  );
  useEffect(() => {
    onChangeRadio();
  }, [summary, selected]);

  const { en, ar, short, ...colors } = productItem;
  const productNames = { en, ar };

  const countryIndex = countryList.indexOf(country);

  const currentProductColor = colorList.includes(color) ? color : colorList[0]; // get the selected color or first by default

  const colorObj = colors[currentProductColor] || {};

  const { price = [], dPrice = [], sku } = colorObj;
  const countryPrice = price[countryIndex];

  const countryDiscoutedPrice = dPrice[countryIndex];
  const currency = t(`common.currencies.${currencyCoutryList[country]}`);

  useEffect(() => {
    try {
      setImage(
        require(`../../assets/products/${collection}/${en}_${currentProductColor}.png`)
      );
    } catch {
      try {
        setImage(require(`../../assets/products/${collection}/${en}.jpg`));
      } catch {
        setImage(require(`../../assets/Homecentre_Watermark_Threekit.png`));
      }
    }
  }, [collection, category, currentProductColor, country, type, size, color]);

  const onChangeRadio = () => {
    if (category === 'Bed') {
      setChecked(!!summary.find((i) => i.en === en));
    } else {
      const selectedModel = handleGetItem(selected);
      setChecked(en === selectedModel?.en);
    }
  };

  const getAssetId = () => attributeIds[en];

  const handleClick = async () => {
    if (!isPlayerReady) return;
    try {
      dispatch(setPlayerReady(false));
      let modelId;
      const newProductObj = {
        en,
        ar,
        short,
        sku,
        price,
        dPrice,
        category,
        size,
        designId,
        collection,
        color: currentProductColor,
        qty: 1,
        type,
        key: en,
        productType: type,
      };
      if (category === 'Sofa') {
        const config = {
          Color: {
            assetId: getColorAssetId(collection, currentProductColor),
          },
        };
        const itemObj = {
          ...newProductObj,
          type: collection,
          configuration: config,
        };
        modelId = await handleAddItem(itemObj);
        await setConfigOnModel(modelId, config);

        window.threekit.configurator.frameSceneAroundLoneItem();
        dispatch(setProduct({ ...newProductObj, modelId }));
      } else {
        const [firstPlayerModel] = await window.threekit.configurator
          .getItems()
          .values();
        modelId = firstPlayerModel.id;

        const key = attributeIds[type]; // Bedbase or Headboard
        const accessoriesId = attributeIds.Accessories[collection][size];

        const typeConfig = {
          [key]: { ...newProductObj, assetId: getAssetId(key) },
        };
        await setConfigOnModel(modelId, typeConfig);

        const colorConfig = {
          Color: {
            assetId: PRODUCT_COLOR_IDS[collection]?.[currentProductColor],
          },
        };
        await setConfigOnModel(modelId, colorConfig);

        if (hasAccessories) {
          const accessoriesConfig = {
            Accessories: { assetId: accessoriesId },
          };
          await setConfigOnModel(modelId, accessoriesConfig);
        }

        window.threekit.configurator.configureItems(modelId, {
          configuration: { ...typeConfig, ...colorConfig },
          type: 'Bed Collection',
          key: en,
          qty: 1,
          productType: type,
          color: currentProductColor,
        });

        window.threekit.configurator.frameSceneAroundItems();

        // add new summary type product
        const findTypeSummerIndex = summary.findIndex((i) => i.type === type);
        const typeIndex =
          findTypeSummerIndex > -1 ? findTypeSummerIndex : summary.length; // get index to replace or add new product
        const newAddOnsSummary = addOnsSummary;
        const newSummary = [...summary];
        newSummary[typeIndex] = { ...newProductObj, modelId };
        dispatch(resetSummary({ newSummary, newAddOnsSummary }));
      }
      // TODO: refactor undo to a single function
      const configId = await window.threekit.configurator.saveConfiguration({
        designId,
        collection,
        type,
        color,
      });
      const newPlayerConfigs = [...undoConfigArray, configId];
      dispatch(setUndoConfigArray(newPlayerConfigs));
      // saveUndoConfiguration();
      if (!colorList.includes(color)) {
        dispatch(setProductColor(currentProductColor));
      }
    } catch (err) {
      console.log('Failed to add a product', err);
      dispatch(setPlayerReady(true));
    }
    dispatch(setPlayerReady(true));
  };
  const onDragStart = (e) => {
    const data = JSON.stringify({
      en,
      ar,
      short,
      sku,
      price,
      dPrice,
      category,
      size,
      designId,
      collection,
      currentProductColor,
      qty: 1,
      type,
      colorList,
    });
    e.dataTransfer.setData('data', data);
    e.dataTransfer.setDragImage(
      e.target,
      0.5 * e.target.clientWidth,
      0.5 * e.target.clientWidth
    );
  };

  //MOBILE
  function productClick() {
    if (!isPlayerReady) return;
    document
      .querySelector('.overlap-hidden')
      .classList.remove('configrator-mobile');
    document.querySelector('.play-area').classList.remove('d-none');
    document.querySelector('.pick').classList.toggle('d-none');
    document.querySelector('.return').classList.toggle('d-none');
  }

  const counter = summary.length ? summary.filter((i) => i.en === en) : 0;

  return !countryPrice ? null : ( // skip product if no price or not None
    <label className="col-sm-6 col-6 custom_grid_padding full_height">
      <Wrapper
        disabled={!isPlayerReady}
        checked={isChecked}
        onClick={() => {
          handleClick();
          productClick();
        }}
      >
        <div className="card-box-price">{counter.length || 0}</div>
        <div className="img">
          <img
            className="w-100"
            alt={short}
            draggable="true"
            onDragStart={onDragStart}
            src={image}
          />
        </div>
        <div className="product-box-des">
          <ProductTitle>{productNames[language]} </ProductTitle>
          <ProductPrice>
            {currency}{' '}
            <FormattedPrice
              price={countryPrice}
              dPrice={countryDiscoutedPrice}
            />{' '}
            {!!countryDiscoutedPrice && (
              <FormattedPrice dPrice={countryDiscoutedPrice} />
            )}
          </ProductPrice>
        </div>
      </Wrapper>
      <HiddenInput
        type="radio"
        value={value}
        name={name}
        checked={isChecked}
        onChange={onChangeRadio}
      />
    </label>
  );
};

export default ProductCard;
